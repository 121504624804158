// @flow
import { keyframes } from 'styled-components'

export const fastDuration = 200
export const slowDuration = 400

export const fastTransition = `${fastDuration}ms ease-in-out`
export const slowTransition = `${slowDuration}ms ease-in-out`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const fadeInUp = keyframes`
  from {
    transform: translate3d(0, 200px, 0);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
`

export const fadeInDown = keyframes`
  from {
    transform: translate3d(0, -200px, 0);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
`

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const scale = keyframes`
  0% {
    transform: scale(0);
  }

  75% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
`

export const grow = (width: string | number | *) => keyframes`
  from {
    width: 0;
  }

  to {
    width: ${width};
  }
`
