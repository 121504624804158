// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../../containers/Data'
import ModalContainer from '../../containers/Modal'

// Components
import ContentWrapper from './ContentWrapper'
import Button from '../Button'

// Types
import { type ISentResults } from '../../types/form'
import { sectionTitle } from '../../styles/textStyles'

type IProps = {
  content: ISentResults,
  close: () => void
}

const SentResults = ({ content, close }: IProps) => (
  <ContentWrapper>
    <Title>{content.title}</Title>
    <Text>{content.text}</Text>
    <Button onClick={close}>{content.button}</Button>
  </ContentWrapper>
)

const ConnectedSentResults = () => (
  <Subscribe to={[DataContainer, ModalContainer]}>
    {({ state }, { close }) => (
      <SentResults content={state.content.sendResults} close={close} />
    )}
  </Subscribe>
)

const Title = styled.h3`
  ${sectionTitle};
  margin: 40px 0 24px;
`

const Text = styled.p`
  margin: 0 0 32px;
`

export { SentResults }
export default ConnectedSentResults
