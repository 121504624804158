import { css } from 'styled-components'

export const solidFocus = css`
  outline-color: ${props => props.theme.highlightColor};
  outline-offset: 2px;
  outline-style: solid;
  outline-width: 2px;
  -moz-outline-radius: 4px;
`

export const checked = css`
  background-color: ${props => props.theme.metaColor};
`
