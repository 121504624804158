// @flow
import React from 'react'
import styled from 'styled-components'

// Styles
import { subsectionTitle, body } from '../styles/textStyles'
import { checked } from '../styles/states'
import { fastTransition } from '../styles/animations'

// Types
type IAnswerProps = {
  answer: string,
  statementId: string,
  checked: boolean,
  unfocused: boolean,
  setAnswer: (id: string, answer: string) => void,
  children: React$Node
}

const Answer = ({
  statementId,
  answer,
  setAnswer,
  checked,
  unfocused,
  children
}: IAnswerProps) => {
  const handleChange = () => setAnswer(statementId, answer)

  return (
    <Container key={answer} unfocused={unfocused}>
      <Radio
        id={statementId + answer}
        name={statementId}
        value={answer}
        onChange={handleChange}
        checked={checked}
      />
      <Label htmlFor={statementId + answer}>
        <Nr>{answer}.</Nr>
        <Text>{children}</Text>
      </Label>
    </Container>
  )
}

export const Container = styled.div`
  margin-bottom: 12px;
  user-select: none;
  transition: opacity ${fastTransition};

  :last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.unfocused &&
    `
    opacity: 0.7;
  `};
`

export const Label = styled.label`
  display: flex;
  padding: 6px 20px;
  border: 1px solid ${props => props.theme.textInvertedColor};
  background-color: ${props => props.theme.textInvertedColor};
  cursor: pointer;
`

type IRadio = {
  id: string,
  name: string,
  value: string,
  onChange: () => void,
  checked: boolean
}

export const Radio = styled((props: IRadio) => (
  <input type="radio" {...props} />
))`
  position: absolute;
  opacity: 0;

  :checked + ${Label} {
    ${checked}
  }
`

const Nr = styled.span`
  ${subsectionTitle};
  margin-bottom: 0;
  font-size: 24px;
`

const Text = styled.span`
  ${body};
  margin: auto 20px;
`

export default Answer
