// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { fastTransition } from '../styles/animations'

type IProps = {
  progress: number,
  className?: string
}

const ProgressBar = ({ progress, className }: IProps) => (
  <Container className={className}>
    <Progress progress={progress} />
  </Container>
)

ProgressBar.defaultProps = {
  progress: 0
}

const Container = styled.div`
  box-sizing: border-box;
  width: 240px;
  height: 15px;
  background-color: ${props => props.theme.backgroundColor};
  overflow: hidden;
`

const Progress = styled.div`
  width: 100%;
  height: 100%;
  transform: ${props =>
    props.progress
      ? `translateX(${props.progress - 100}%);`
      : `translateX(-100%);`};
  transition: transform ${fastTransition};
  background-color: ${props => props.theme.metaColor};
`

export default ProgressBar
