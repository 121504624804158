// @flow
import React from 'react'
import styled from 'styled-components'

// Config
import { paths } from '../routes'

// Styling
import { mqFrom } from '../styles/mediaQueries'

// Componnets
import ContentWrapperComponent from './ContentWrapper'
import ProgressBarComponent from './ProgressBar'
import Button from './Button'
import LinkButton from './LinkButton'

// Types
import { type IContentChapterBlock } from '../types/data'

type IProps = {
  labels: IContentChapterBlock,
  duration: number,
  numberOfQuestions: number,
  numberOfQuestionsAnswered: number,
  done: boolean,
  started: boolean
}

const ChapterFooter = ({
  labels,
  duration,
  numberOfQuestions,
  numberOfQuestionsAnswered,
  done,
  started
}: IProps) => {
  return (
    <Container>
      <ContentWrapper>
        <ProgressWrapper>
          <span>
            {started
              ? `
              ${labels.progress.questions} ${numberOfQuestionsAnswered} ${labels.progress.outOf}
                ${numberOfQuestions}
              `
              : `
                ${numberOfQuestions} ${labels.progress.questions}
              `}

            {!done &&
              `
              • ${labels.progress.approx} ${duration}
              ${labels.progress.minutes}
            `}

            {!done && started && `${labels.progress.left}`}
          </span>
          <ProgressBar
            progress={(numberOfQuestionsAnswered / numberOfQuestions) * 100}
          />
        </ProgressWrapper>
        <ButtonWrapper>
          <Button as={LinkButton} to={paths.overview} secondary>
            {labels.buttons.back}
          </Button>
          <Button type="submit" primary>
            {labels.buttons.continue}
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 20px 0;
  background-color: ${props => props.theme.textColor};
  color: ${props => props.theme.backgroundColor};
`

const ContentWrapper = styled(ContentWrapperComponent)`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 100%;
  text-align: center;

  ${mqFrom.S`
    flex-direction: row;
    text-align: left;
  `};
`

const ProgressWrapper = styled.div`
  flex-direction: column;
  display: flex;

  ${mqFrom.M`
    flex-direction: row;
  `};

  span {
    margin: auto 32px auto 0;
    margin-bottom: 12px;

    ${mqFrom.S`
      margin-bottom: 0;
    `};
  }
`

const ProgressBar = styled(ProgressBarComponent)`
  display: none;

  ${mqFrom.L`
    display: block;
    margin: auto 32px auto 0;
  `};
`

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;

  a:first-child {
    margin-right: 12px;
  }
`

export default ChapterFooter
