// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../containers/Data'

// Components
import ContentWrapperComponent from './ContentWrapper'
import Form from './forms/RequestReport'

// Styling
import { sectionTitle, label } from '../styles/textStyles'

// Types
import { type IRequestReport } from '../types/form'

type IProps = IRequestReport

const RequestReport = ({ title, text, button, note }: IProps) => (
  <Wrapper>
    <ContentWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <Form button={button} />
      <Note>* {note}</Note>
    </ContentWrapper>
  </Wrapper>
)

const ConnectedRequestReport = () => (
  <Subscribe to={[DataContainer]}>
    {({ state }) => (
      <RequestReport
        title={state.content.results.receiveResults.title}
        text={state.content.results.receiveResults.text}
        button={state.content.results.receiveResults.button}
        note={state.content.results.receiveResults.note}
      />
    )}
  </Subscribe>
)

const Wrapper = styled.div`
  background-color: ${props => props.theme.textColor};
  color: ${props => props.theme.backgroundColor};
`

const ContentWrapper = styled(ContentWrapperComponent)`
  padding-top: 52px;
  padding-bottom: 64px;
`

const Title = styled.h2`
  ${sectionTitle};
  margin-top: 0;
  margin-bottom: 12px;
`

const Text = styled.p`
  margin-top: 0;
  margin-bottom: 18px;
`

const Note = styled.span`
  ${label};
  color: ${props => props.theme.highlightColor};
`

export { RequestReport }
export default ConnectedRequestReport
