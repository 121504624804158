// @flow
import React from 'react'

// Types
import { type IRichContent, type IBlock } from '../../types/data'

// Components
import Paragraph from './Paragraph'
import List from './List'
import Heading from './Heading'

type IProps = {
  content: IRichContent,
  className?: string
}

const renderBlock = (block: IBlock, key: number) => {
  switch (block.type) {
    case 'paragraph': {
      return <Paragraph {...block} key={key} />
    }
    case 'list': {
      return <List {...block} key={key} />
    }
    case 'heading3': {
      return <Heading {...block} key={key} />
    }
    default: {
      console.error(
        `Content Block with the type ${block.type} is not supported`,
        block
      )
      return null
    }
  }
}

const ContentBlocks = ({ content, className }: IProps) => (
  <section className={className}>
    {content.map((block, key) => renderBlock(block, key))}
  </section>
)

export default ContentBlocks
