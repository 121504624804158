// @flow
import { Container } from 'unstated'
import axios from 'axios'

// Styling
import {
  pageBackgroundColor,
  textColor,
  textInvertedColor,
  highlightColor,
  backgroundColor,
  metaColor
} from '../styles/colors'

import { type IDataState, type ITheme } from '../types/data'

const initialState = {
  loading: true,
  settings: {
    theme: {
      title: 'Digital Maturity',
      copyrightHolder: 'JungleMinds',
      hostname: '*',
      logo: {
        dimensions: {
          width: 220,
          height: 30
        },
        alt: 'Digital Maturity',
        copyright: 'JungleMinds',
        url: 'static/digital_maturity_logo_220x30.svg'
      },
      colors: {
        pageBackgroundColor,
        textColor,
        textInvertedColor,
        highlightColor,
        backgroundColor,
        metaColor
      }
    },
    themes: []
  },
  hash: '',
  chapters: [],
  content: {}
}

class DataContainer extends Container<*, IDataState> {
  constructor() {
    super()
    this.state = initialState

    // Fetch CMS Data
    axios
      .get('/data.json')
      .then(result => {
        this.setState(
          {
            settings: {
              ...this.state.settings,
              theme: this.getThemeByHost(result.data.settings.themes)
            },
            hash: result.data.hash,
            chapters: result.data.content
          },
          this.setLoadingState
        )
      })
      .catch(err => {
        console.error(`Something went wrong fetching data.json`, {
          details: err
        })
      })

    // Fetch StaticContent Data
    axios
      .get('/content.json')
      .then(result => {
        this.setState(
          {
            content: result.data,
            home: result.data.home
          },
          this.setLoadingState
        )
      })
      .catch(err => {
        console.error(`Something went wrong fetching content.json`, {
          details: err
        })
      })
  }

  setLoadingState = (): void => {
    if (
      this.state.content !== initialState.content &&
      this.state.settings !== initialState.settings
    ) {
      this.setState({
        loading: false
      })
    }
  }

  getThemeByHost = (themes: Array<ITheme>): ITheme => {
    if (window && window.location && window.location.host) {
      const theme = themes.find(
        theme => theme.hostname === window.location.host
      )
      if (theme) {
        return theme
      }
    }
    return this.state.settings.theme // return default theme
  }
}

export default DataContainer
