// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { subsectionTitle, intro, label } from '../../styles/textStyles'
import { mqFrom } from '../../styles/mediaQueries'

// Components
import Image from '../Image'
import PennantComponent from '../Pennant'
import ContentBlocks from '../ContentBlocks'
import ContentWrapper from './ContentWrapper'

// Types
import { type IBestPractice } from '../../types/data'
type IModalProps = IBestPractice & {
  pennantLabel: string
}

const theme = {
  dark: true
}

const BestPractice = ({
  pennantLabel,
  title,
  image,
  caption,
  description,
  introduction,
  content
}: IModalProps) => (
  <>
    <Pennant>{pennantLabel}</Pennant>
    <ContentWrapper theme={theme}>
      <Title>{title}</Title>
    </ContentWrapper>
    {image && (
      <Img
        src={image.url}
        alt={image.alt !== null ? image.alt : `${title}`}
        mode="bg"
        lazyLoad={false}
      />
    )}
    <ContentWrapper>
      {caption && <Caption>{caption}</Caption>}
      {introduction !== null && <Introduction content={[introduction]} />}
      {content !== null && <ContentBlocks content={content} />}
    </ContentWrapper>
  </>
)

const Pennant = styled(PennantComponent)`
  position: absolute;
  top: 90px;
  z-index: 1;
  padding: 12px 20px;
  background-color: ${props => props.theme.metaColor};
  color: ${props => props.theme.textColor};

  ${mqFrom.M`
    top: 120px;
  `};
`

const Title = styled.h3`
  ${subsectionTitle};
  margin: 0;
  padding: 25px 0;
`

const Img = styled(Image)`
  width: 100%;
  height: 200px;
  margin-bottom: 8px;

  ${mqFrom.M`
    height: 324px;
    margin-bottom: 12px;
  `};
`

const Caption = styled.span`
  ${label};
  display: block;
  width: 100%;
  margin-bottom: 28px;
  color: ${props => props.theme.metaColor};
  text-align: center;
`

const Introduction = styled(ContentBlocks)`
  p {
    ${intro};
    margin-top: 28px;
    margin-bottom: 24px;

    ${mqFrom.M`
      margin-bottom: 32px;
    `};
  }
`

export default BestPractice
