// @flow
import React from 'react'

// Styling
import { textColor } from '../../styles/colors'

type IProps = {
  color?: string
}

const User = ({ color, ...props }: IProps) => (
  <svg viewBox="0 0 83 75" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M35.636 71.319c.323-10.344 5.771-21.917 13.796-25.906a15.02 15.02 0 0 1-9.001-13.775c0-8.293 6.7-15.015 14.965-15.015s14.966 6.723 14.966 15.015a15.02 15.02 0 0 1-9.003 13.775c8.026 3.989 13.476 15.563 13.798 25.906h5.36c.886 0 1.604.72 1.604 1.609 0 .888-.718 1.608-1.604 1.608H2.483c-.886 0-1.604-.72-1.604-1.608 0-.889.718-1.61 1.604-1.61h33.153zm2.753 0h34.015c-.456-11.839-9.154-24.56-17.009-24.56-7.853 0-16.55 12.721-17.006 24.56zm28.766-39.681c0-6.515-5.265-11.797-11.76-11.797-6.493 0-11.757 5.281-11.757 11.797 0 6.515 5.264 11.797 11.758 11.797 6.494 0 11.76-5.282 11.76-11.797zm-35.993.14a1.601 1.601 0 0 1 1.204-.546h3.385c.886 0 1.604.72 1.604 1.608 0 .889-.718 1.61-1.604 1.61h-2.66l-2.694 3.076a1.6 1.6 0 0 1-2.433-.03L25.42 34.45H11.426c-2.866 0-5.202-2.352-5.202-5.233V5.77c0-2.881 2.336-5.234 5.202-5.234h40.907c2.867 0 5.201 2.352 5.201 5.234v5.967c0 .889-.717 1.609-1.603 1.609s-1.603-.72-1.603-1.609V5.77c0-1.108-.903-2.016-1.995-2.016H11.426c-1.092 0-1.995.909-1.995 2.016v23.446c0 1.106.903 2.016 1.995 2.016h14.742c.475 0 .925.21 1.23.576l1.824 2.185 1.94-2.214zM15.31 13.407c-.885 0-1.603-.72-1.603-1.609 0-.888.718-1.609 1.603-1.609h25.656c.885 0 1.603.72 1.603 1.61 0 .888-.718 1.608-1.603 1.608H15.31zm0 8.58c-.885 0-1.603-.72-1.603-1.61 0-.888.718-1.608 1.603-1.608h13.897c.885 0 1.603.72 1.603 1.609 0 .888-.718 1.609-1.603 1.609H15.31z"
      fillRule="nonzero"
      fill={color}
    />
  </svg>
)

User.defaultProps = {
  color: textColor
}

export default User
