// @flow
import styled from 'styled-components'

// Styles
import { textInvertedColor } from '../styles/colors'
import { label } from '../styles/textStyles'
import { fontWeights, fonts } from '../styles/fonts'

const Pennant = styled.div`
  ${label};
  position: relative;
  display: inline-block;
  padding: 6px 30px;
  background-color: ${props => props.theme.highlightColor};
  color: ${textInvertedColor};
  font-family: ${fonts.primary};
  font-size: 16px;
  font-weight: ${fontWeights.bold};

  &:after {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 0;
    height: 0;
    border-width: 0 15px 8px 0;
    border-style: solid;
    border-color: transparent;
    border-right-color: rgba(0, 0, 0, 0.7);
    background-color: transparent;
    content: '';
  }
`

export default Pennant
