// @flow
import { type ComponentType } from 'react'

import HomePage from './components/pageTypes/Home'
import OverviewPage from './components/pageTypes/Overview'
import ChapterPage from './components/pageTypes/Chapter'
import ResultsPage from './components/pageTypes/Results'
import PageNotFound from './components/pageTypes/PageNotFound'
import PagePDFGenerator from './server/PdfGeneratorDevPage'

// Types
import { type IChapter } from './types/data'

type Paths = {
  [key: string]: string
}

export type Route = {
  path: string,
  title: string,
  component: ComponentType<*>,
  exact?: boolean
}

export const paths: Paths = {
  home: '/',
  overview: '/overview',
  chapters: '/chapters',
  results: '/results',
  pageNotFound: '*'
}

export const strToSlug = (str: string): string => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaaaeeeeiiiioooouuuunc------'

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '') // trim - from end of text

  return str
}

const routes = (chapters: Array<IChapter>): Array<Route> => {
  let routeConfig = [
    {
      path: paths.home,
      title: 'Homepage',
      component: HomePage,
      exact: true
    },
    {
      path: paths.overview,
      title: 'Overview',
      component: OverviewPage,
      exact: true
    },
    {
      path: paths.results,
      title: 'Results',
      component: ResultsPage,
      exact: true
    }
  ]

  chapters.forEach(chapter => {
    routeConfig.push({
      path: `${paths.chapters}/${strToSlug(chapter.title)}`,
      title: chapter.title,
      component: ChapterPage,
      exact: true
    })
  })

  if (process.env.NODE_ENV === 'development') {
    routeConfig.push({
      path: '/dev/pdf-generator',
      title: 'PDF Generator',
      component: PagePDFGenerator
    })
  }

  routeConfig.push({
    path: paths.pageNotFound,
    title: '404',
    component: PageNotFound
  })

  return routeConfig
}

export default routes
