// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { FastField } from 'formik'

// Utils
import { scoreToChar } from '../utils/scores'

// Styling
import { subsectionUnderTitle } from '../styles/textStyles'
import { errorColor } from '../styles/colors'
import { slowTransition } from '../styles/animations'
import { mqFrom } from '../styles/mediaQueries'

// Containers
import ProgressContainer from '../containers/Progress'

// Components
import Answer from './Answer'
import ContentWrapperComponent from './ContentWrapper'
import Exclamation from './icons/Exclamation'

// Types
import { type IStatement } from '../types/data'
type IOwnProps = IStatement & {
  chapterId: string,
  id: string
}

type IProps = IStatement & {
  id: string,
  selected: string,
  error: boolean,
  setAnswer: (id: string, answer: string) => void,
  className?: string
}

const Statement = ({
  id,
  text,
  answers,
  selected,
  error,
  setAnswer,
  className
}: IProps) => {
  return (
    <ContentWrapper>
      <Fieldset className={className} error={error} data-id={id}>
        <Error>
          <Icon width={4} height={15} />
        </Error>
        <StatementText>{text}</StatementText>

        {answers.map((option, i) => {
          // convert 0,1,2,... to A,B,C,...
          const answer = scoreToChar(i)
          return (
            <Answer
              key={i}
              statementId={id}
              answer={answer}
              checked={answer === selected}
              setAnswer={setAnswer}
              unfocused={!!selected && answer !== selected}
            >
              {option.text}
            </Answer>
          )
        })}
      </Fieldset>
    </ContentWrapper>
  )
}

const ConnectedStatement = ({
  chapterId,
  id,
  text,
  answers,
  ...rest
}: IOwnProps) => (
  <Subscribe to={[ProgressContainer]}>
    {({ state, setAnswer }) => {
      return (
        <FastField name={id}>
          {({ field, form }) => {
            const setAnswerHandler = (id: string, answer: string) => {
              form.setFieldValue(id, answer)
              setAnswer(chapterId, id, answer)
            }

            return (
              <Statement
                id={id}
                text={text}
                answers={answers}
                selected={field.value}
                error={form.submitCount && form.errors[field.name]}
                setAnswer={setAnswerHandler}
                {...rest}
              />
            )
          }}
        </FastField>
      )
    }}
  </Subscribe>
)

const Error = styled.div`
  position: absolute;
  top: -5px;
  left: -54px;
  display: flex;
  width: 30px;
  height: 30px;
  transform: translateX(20px);
  transition: opacity ${slowTransition}, transform ${slowTransition};
  border-radius: 4px;
  background-color: ${errorColor};
  opacity: 0;

  :after {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid ${errorColor};
    transform: translateY(-50%);
    content: '';
  }
`

const Icon = styled(Exclamation)`
  margin: auto;
`

const StatementText = styled.legend`
  ${subsectionUnderTitle};
  display: block;
  margin-bottom: 25px;
`

const ContentWrapper = styled(ContentWrapperComponent)`
  position: relative;
`

const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 4px;

  ${props =>
    props.error &&
    `
    ${StatementText} {
      color: ${errorColor};
    }
  `};

  ${mqFrom.px(1170)`
    ${props =>
      props.error &&
      `
      ${StatementText} {
        color: ${props.theme.textColor};
      }

      ${Error} {
        transform: translateX(0);
        opacity: 1;
      }
    `};
  `};
`

export { Statement }
export default ConnectedStatement
