// @flow
import React from 'react'
import styled from 'styled-components'

// Components
import Paragraph from './Paragraph'

// Types
import { type ICMSList, type ICMSListChildren } from '../../types/data'

const renderChildren = (children: ICMSListChildren) =>
  children.map((block, key) => (
    <Paragraph
      as="li"
      text={block.text}
      spans={block.spans}
      type="paragraph"
      key={key}
    />
  ))

const List = ({ listType, children }: ICMSList) =>
  listType === 'sorted' ? (
    <ListElement>{renderChildren(children)}</ListElement>
  ) : (
    <ListElement as="ol">{renderChildren(children)}</ListElement>
  )

const ListElement = styled.ul`
  margin: 16px 0;

  li {
    margin: 0 0 12px;

    :last-child {
      margin: 0 0 16px;
    }
  }
`

export default List
