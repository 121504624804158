// @flow
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Observer from 'react-intersection-observer'

// Styling
import { fadeIn } from '../styles/animations'

type IDefaultProps = {
  mode: 'img' | 'bg',
  lazyLoad: boolean
}

type IProps = IDefaultProps & {
  src: string,
  title?: string,
  inView?: boolean,
  alt?: string,
  className?: string,
  onLoad?: () => void,
  onError?: () => void
}

type IImageProps = IProps & {
  observerRef?: () => ?HTMLElement
}

type IState = {
  loaded: boolean,
  error: boolean
}

const MEDIA_URL = '/media/'

class ImageElement extends PureComponent<IImageProps, IState> {
  state = {
    loaded: false,
    error: false
  }

  componentDidMount() {
    if (this.props.inView) {
      this.startLoadingImage()
    }
  }

  componentDidUpdate(prevProps: IImageProps) {
    if (this.props.inView && !prevProps.inView && !this.state.loaded) {
      this.startLoadingImage()
    }
  }

  startLoadingImage = () => {
    const image = new Image()
    image.onload = this.handleLoad
    image.onerror = this.handleError
    image.src = `${MEDIA_URL}${this.props.src}`
  }

  handleLoad = () => {
    this.setState({ loaded: true }, () => {
      const { onLoad } = this.props
      if (typeof onLoad === 'function') {
        onLoad()
      }
    })
  }

  handleError = () => {
    this.setState({ loaded: true, error: true }, () => {
      const { onError } = this.props
      if (typeof onError === 'function') {
        onError()
      }
    })
  }

  render() {
    const { mode, title, inView, alt, src, observerRef, ...props } = this.props

    const { loaded, error } = this.state

    return inView ? (
      <Container ref={observerRef} {...props}>
        {loaded && mode === 'bg' && (
          <ImageBG
            src={`${MEDIA_URL}${src}`}
            error={error}
            role="img"
            aria-label={alt}
          />
        )}
        {loaded && mode === 'img' && (
          <ImageIMG
            alt={alt}
            title={title}
            src={`${MEDIA_URL}${src}`}
            error={error}
            {...props}
          />
        )}
      </Container>
    ) : (
      <ImageNotInViewport ref={observerRef} {...props} />
    )
  }
}

class ImageLazyLoad extends PureComponent<IProps> {
  static defaultProps: IDefaultProps = {
    mode: 'img',
    lazyLoad: true
  }

  render() {
    return this.props.lazyLoad ? (
      <Observer rootMargin="-50px 0px 150px 0px" triggerOnce>
        {({ inView, ref }) => (
          <ImageElement {...this.props} observerRef={ref} inView={inView} />
        )}
      </Observer>
    ) : (
      <ImageElement {...this.props} inView />
    )
  }
}

const Container = styled.div`
  position: relative;
  display: flex;
`

const errorStyles = props =>
  props.error &&
  css`
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.textColor};
    font-size: 10px;
    line-height: 5em;
    text-indent: 2em;
  `

const animationStyles = css`
  opacity: 0;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-duration: 0.45s;
  animation-name: ${fadeIn};
`

export const ImageBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-position: 50% 50%;
  background-size: cover;
  ${animationStyles};
  ${errorStyles};
`

const ImageIMG = styled.img`
  align-self: center;
  display: flex;
  width: 100%;
  ${animationStyles};
  ${errorStyles};
`

const ImageNotInViewport = styled.div`
  background: ${props => props.theme.backgroundColor};
`

export default ImageLazyLoad
export { ImageElement }
