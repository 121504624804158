// @flow
import { css } from 'styled-components'

// Styles
import { fonts, fontWeights } from './fonts'

// Text styles
export const body = css`
  font-family: ${fonts.primary};
  font-size: 18px;
  font-weight: ${fontWeights.normal};
  line-height: 24px;
`
export const pageTitle = css`
  font-family: ${fonts.primary};
  font-size: 66px;
  font-weight: ${fontWeights.bold};
  line-height: 75px;
`
export const sectionTitle = css`
  font-family: ${fonts.primary};
  font-size: 40px;
  font-weight: ${fontWeights.bold};
  line-height: 45px;
`
export const title = css`
  font-family: ${fonts.primary};
  font-size: 24px;
  font-weight: ${fontWeights.bold};
  line-height: 28px;
`
export const subsectionUnderTitle = css`
  font-family: ${fonts.primary};
  font-size: 30px;
  font-weight: ${fontWeights.bold};
  line-height: 38px;
`
export const subsectionTitle = css`
  font-family: ${fonts.primary};
  font-size: 32px;
  font-weight: ${fontWeights.bold};
  line-height: 38px;
`
export const quoteText = css`
  font-family: ${fonts.primary};
  font-size: 22px;
  font-weight: ${fontWeights.bold};
  line-height: 30px;
`
export const intro = css`
  font-family: ${fonts.primary};
  font-size: 18px;
  font-weight: ${fontWeights.light};
  line-height: 26px;
`
export const underTitle = css`
  font-family: ${fonts.primary};
  font-size: 20px;
  font-weight: ${fontWeights.normal};
  line-height: 30px;
`
export const button = css`
  font-family: ${fonts.primary};
  font-size: 18px;
  font-weight: ${fontWeights.bold};
  line-height: 1;
`
export const label = css`
  font-family: ${fonts.primary};
  font-size: 16px;
  line-height: 1;
`
