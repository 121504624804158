// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { textColor } from '../../styles/colors'
import { intro } from '../../styles/textStyles'
import { mqFrom, mqTo } from '../../styles/mediaQueries'

// Components
import LoaderAnimation from '../Loader'

const Loading = () => (
  <Container>
    <Loader />
    <Label>Loading...</Label>
  </Container>
)

export default Loading

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: calc(100vh - 102px);
  color: ${textColor};
`

const Loader = styled(LoaderAnimation)`
  width: 50px;
  height: 50px;

  ${mqFrom.M`width: 100px; height: 100px;`}
`
const Label = styled.p`
  ${intro}
  margin: 0;
  ${mqTo.M`font-size: 12px;`}
`
