// @flow
import React from 'react'
import styled from 'styled-components'

// Components
import ImageComponent from './Image'
import ContentWrapperComponent from './ContentWrapper'
import Quote from './Quote'

// Styles
import { textColor, pageBackgroundColor } from '../styles/colors'
import { pageTitle, underTitle } from '../styles/textStyles'
import { mqFrom } from '../styles/mediaQueries'

// Types
import { type IHeaderHome } from '../types/data'

const HeaderHome = ({ image, title, text, quote, cite }: IHeaderHome) => (
  <>
    {image !== null && (
      <Image src={image.url} mode="bg" title={title} alt={image.alt || title} />
    )}
    <ContentWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <QuoteWrapper>
        <Quote quote={quote} cite={cite} />
      </QuoteWrapper>
    </ContentWrapper>
  </>
)

const Image = styled(ImageComponent)`
  height: 200px;

  ${mqFrom.M`
    height: 280px;
  `};

  ${mqFrom.L`
    height: 380px;
  `};
`

const ContentWrapper = styled(ContentWrapperComponent)`
  padding-top: 64px;
  padding-bottom: 52px;
  background-color: ${textColor};
  color: ${pageBackgroundColor};

  ${mqFrom.M`
    padding-bottom: 90px;
  `};

  @media (min-width: 1030px) {
    max-width: 100%;
    margin: 0;
    padding: 60px calc((100% - 1030px) / 2) 90px;
  }
`

const Title = styled.h1`
  ${pageTitle};
  margin: 0 0 32px;
  padding-bottom: 30px;

  ${mqFrom.M`
    max-width: 400px;
    margin: 0;
  `};

  ${mqFrom.L`
    max-width: 504px;
  `};
`

const Text = styled.p`
  margin: 0 0 32px;
  ${underTitle}

  ${mqFrom.M`
    max-width: 400px;
    margin: 0;
  `};

  ${mqFrom.L`
    max-width: 504px;
  `};
`

const QuoteWrapper = styled.div`
  flex-direction: column;
  display: flex;
  height: 0;
  padding-bottom: 120px;

  ${mqFrom.M`
    padding-bottom: 0;
  `};
`

export default HeaderHome
