// @flow
import styled from 'styled-components'

import { mqFrom } from '../styles/mediaQueries'

const ContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1030px;
  margin: 0 auto;
  padding-right: 16px;
  padding-left: 16px;

  /* 1062 = max-width + padding */
  ${mqFrom.px(1062)`padding-right: 0; padding-left: 0;`}
`

export default ContentWrapper
