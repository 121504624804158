import { WritableStreamBuffer } from 'stream-buffers'

import { isServer } from '../../utils/getRenderPlatform'
import { report as getReport } from '../../utils/scores'
import * as colors from '../../styles/colors'

let PDFDocument = null

if (isServer()) {
  PDFDocument = require('pdfkit')
}

const DPI = 300
const pageWidth = 2480
const pageHeight = 3508
const paperSize = [pageWidth, pageHeight] // A4@300dpi
const paperMargins = {
  top: DPI,
  bottom: DPI,
  left: DPI,
  right: DPI
}
const pageConfig = {
  size: paperSize,
  margins: paperMargins
}

const addPage = doc =>
  doc
    .addPage(pageConfig)
    .font('Helvetica')
    .fillColor(colors.textColor)
    .fontSize(31)

const addChaptersBars = (startX, startY, doc, chapters, score) => {
  const chapterCount = chapters.length
  const width = 600

  chapters.forEach((chapter, i) => {
    const chapterScore = score.chapters[chapter.id]
    // Chapter Label
    doc
      .fillColor(colors.textColor)
      .text(chapter.title, startX - 20, startY + 30 + i * 95, {
        width: 600,
        align: 'right'
      })

    // Chapter Bar
    doc
      .roundedRect(
        startX + 610,
        startY + i * 95,
        (width / 100) * chapterScore,
        80,
        8
      )
      .fillColor(i % 2 ? colors.textColor : colors.metaColor)
      .fill()

    // Chapter Outlines
    doc
      .roundedRect(startX + 610, startY + i * 95, width, 80, 8)
      .strokeColor(colors.textColor)
      .stroke()
  })

  // lines
  const endY = startY + 20 + chapterCount * 90
  let numberOfLines = 6
  let i = numberOfLines
  while (i--) {
    doc
      .moveTo(startX + 610 + (width / (numberOfLines - 1)) * i, startY - 10)
      .lineTo(startX + 610 + (width / (numberOfLines - 1)) * i, endY)
      .stroke()
  }

  // Fully capable line
  doc.fontSize(25).text('fully capable', startX + 1150, startY - 60)
}

const addHorizonsBars = (startX, startY, doc, chapters, score) => {
  // Set X-Y of document
  doc.translate(startX, startY)

  // Beyond path and line
  doc
    .path(
      'M584.25 1.938l93-1.938v124l-277.063 1.938c39.324-49.742 70-82.033 92.032-96.876C514.249 14.22 544.927 5.18 584.25 1.938z'
    )
    .fillColor(colors.textColor)
    .fill()

  doc
    .moveTo(500, 90)
    .lineTo(60, 90)
    .stroke()

  // Beyond text and score
  doc
    .fontSize(45)
    .font('Helvetica')
    .text('Beyond ', 60, 25, { continued: true })
    .font('Helvetica-Bold')
    .text(`${score.horizons.beyond}%`)

  // Tomorrow path and line
  doc
    .path(
      'M404.125 127.938L677.313 126v122.063c-111.479-.228-195.114-.228-250.907 0-55.792.227-127.928.227-216.406 0 36.417-52.313 66.573-85.25 90.469-98.813 23.896-13.563 58.448-20.667 103.656-21.313z'
    )
    .fillColor(colors.metaColor)
    .fill()

  doc
    .moveTo(400, 210)
    .lineTo(-100, 210)
    .stroke()

  // Tomorrow text and score
  doc
    .fillColor(colors.textColor)
    .font('Helvetica')
    .text('Tomorrow ', -100, 150, { continued: true })
    .font('Helvetica-Bold')
    .text(`${score.horizons.tomorrow}%`)

  // Today path and line
  doc
    .path(
      'M222 250h455.313v124H5c41.972-53.785 75.233-87.691 99.781-101.719C129.33 258.254 168.403 250.827 222 250z'
    )
    .fillColor(colors.textColor)
    .fill()

  doc
    .moveTo(350, 335)
    .lineTo(-250, 335)
    .stroke()

  // Today text and score
  doc
    .font('Helvetica')
    .text('Today ', -246, 270, { continued: true })
    .font('Helvetica-Bold')
    .text(`${score.horizons.today}%`)

  // Reset X-Y of document
  doc.translate(0, 0)

  // Reset text styles
  doc.font('Helvetica').fontSize(31)
}

const addHorizonsPages = (doc, chapters, answers) => {
  const generatedReport = getReport(chapters, answers)

  Object.keys(generatedReport).forEach(page => {
    addPage(doc)

    page = generatedReport[page]

    // Horizon title
    doc
      .font('Helvetica')
      .fontSize(50)
      .text(page.title, 240, 155)
      .moveDown(1.5)

    // Render chapters
    Object.keys(page.chapters).forEach(chapterId => {
      const answers = page.chapters[chapterId]

      // Chapter name
      doc
        .fontSize(40)
        .text(chapters.find(({ id }) => id === chapterId).title, 400)
        .moveDown(1)

      // Render answers
      answers.forEach(answer => {
        // Answer bullet
        doc
          .fontSize(35)
          .text('›', 450)
          .moveDown(-0.9)

        // Answer name
        doc
          .fontSize(32)
          .text(`${answer}`, 490, null, { lineGap: 4 })
          .moveDown(0.8)
      })

      doc.moveDown(1)
    })
  })

  // Reset text styles
  doc.font('Helvetica').fontSize(31)
}

const generate = ({ name, email, content, chapters, answers }, scores) =>
  new Promise((resolve, reject) => {
    const doc = new PDFDocument({
      autoFirstPage: false,
      info: {
        title: 'Digital Maturity Assessment',
        author: 'Jungle Minds',
        subject: 'Digital Maturity Assessment',
        keywords: 'digital, maturity, assessment, report'
      },
      ...pageConfig
    })
    const buffer = new WritableStreamBuffer()
    doc.pipe(buffer)

    // Start Page
    addPage(doc)

    // Title
    doc
      .fontSize(70)
      .font('Helvetica-Bold')
      .text(content.title, 240, 155, { width: 1000 })
      .font('Helvetica')
      .fontSize(31)

    // Intro
    doc
      .font('Helvetica-Bold')
      .fontSize(40)
      .text(content.paragraph_1.title, 240, 310, { width: 1000, lineGap: 14 })
      .font('Helvetica')
    doc.text(content.paragraph_1.text, 240, 370, { width: 1000, lineGap: 14 })

    // Score: Total
    doc
      .fillColor(colors.textColor)
      .roundedRect(1650, 270, 542, 256, 8)
      .fill()
      .fillColor(colors.textInvertedColor)
      .font('Helvetica-Bold')
      .fontSize(158)
      .text(`${scores.total}%`, 1650, 338, { width: 542, align: 'center' })
      .font('Helvetica')
      .fillColor(colors.textColor)
      .fontSize(31)

    // Score: Chapters
    addChaptersBars(100, 840, doc, chapters, scores)

    // Paragraph_2
    doc
      .fillColor(colors.textColor)
      .font('Helvetica-Bold')
      .fontSize(40)
      .text(content.paragraph_2.title, 1395, 980, { width: 810, lineGap: 14 })
      .fontSize(31)
      .font('Helvetica')
    doc.text(content.paragraph_2.text, 1395, 1040, { width: 810, lineGap: 14 })

    // Paragraph_3
    doc
      .font('Helvetica-Bold')
      .fontSize(40)
      .text(content.paragraph_3.title, 240, 1580, { width: 810, lineGap: 14 })
      .fontSize(31)
      .font('Helvetica')
    doc.text(content.paragraph_3.text, 240, 1640, { width: 810, lineGap: 14 })

    // Score: Horizons
    addHorizonsBars(1550, 1575, doc, chapters, scores)

    // Horizon Pages
    addHorizonsPages(doc, chapters, answers)
    doc.end()

    // send file buffer
    buffer.on('finish', () => {
      resolve(buffer.getContents())
    })
  })

export default generate
