// @flow
import React from 'react'
import styled from 'styled-components'

// Styles
import { label, quoteText } from '../styles/textStyles'
import { mqFrom } from '../styles/mediaQueries'

// Types
import { type IQuote } from '../types/data'

const Quote = ({ quote, cite }: IQuote) => (
  <Container>
    <Text>“{quote}”</Text>
    <Cite>{cite}</Cite>
  </Container>
)

const Container = styled.blockquote`
  box-sizing: border-box;
  z-index: 1;
  align-self: center;
  max-width: 416px;
  margin: 0;
  padding: 24px;
  background: ${props => props.theme.metaColor};
  color: ${props => props.theme.textColor};
  text-align: center;

  ${mqFrom.M`
    align-self: flex-end;
    max-width: 332px;
    margin: -120px 0 0;
    padding: 32px;
  `};

  ${mqFrom.L`
    max-width: 416px;
    min-height: 232px;
    margin: -64px 0 0;
  `};
`

const Text = styled.p`
  ${quoteText};
  margin: 0 0 40px;
`

const Cite = styled.cite`
  ${label};
  margin: 0;
  color: ${props => props.theme.textColor};
  font-style: normal;
`

export default Quote
