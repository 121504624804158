// @flow
import React, { Component } from 'react'
import styled from 'styled-components'

// Components
import ContentWrapperComponent from '../ContentWrapper'
import TabButton from './TabButton'
import TabContent from './TabContent'
import Button from '../Button'
import LinkButton from '../LinkButton'

// Config
import { paths } from '../../routes'

// Styling
import { sectionTitle } from '../../styles/textStyles'

// Types
import {
  type IReport,
  type IHorizonsContent,
  type IHorizon,
  type IChapters
} from '../../types/data'

type IProps = {
  chapters: IChapters,
  report: IReport,
  content: IHorizonsContent
}

type IState = {
  active: IHorizon
}

class Tabs extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      active: Object.keys(props.report)[0]
    }
  }

  setActiveTab = (active: IHorizon) => {
    this.setState({ active })
  }

  render() {
    const { report, content, chapters } = this.props

    return (
      <Container>
        <ContentWrapperComponent>
          <Title>{content.title}</Title>
          <Text>{content.text}</Text>
          <ButtonsContainer>
            {Object.keys(report).map(horizonId => (
              <TabButton
                handleClick={this.setActiveTab}
                key={horizonId}
                horizonId={horizonId}
                title={report[horizonId].title}
                active={this.state.active === horizonId}
              />
            ))}
          </ButtonsContainer>
          <ContentSection>
            {Object.keys(report).map(horizonId => (
              <TabContent
                chapters={chapters}
                key={horizonId}
                active={this.state.active === horizonId}
                reportChapters={report[horizonId].chapters}
              />
            ))}
          </ContentSection>
          <AlignRight>
            <Button primary as={LinkButton} to={paths.overview}>
              {content.button}
            </Button>
          </AlignRight>
        </ContentWrapperComponent>
      </Container>
    )
  }
}

const AlignRight = styled.div`
  text-align: right;
`

const Container = styled.section`
  padding-top: 64px;
  padding-bottom: 64px;
  background: ${props => props.theme.backgroundColor};
`

const Title = styled.h2`
  ${sectionTitle};
  max-width: 520px;
  margin-top: 0;
  margin-bottom: 12px;
`

const Text = styled.p`
  max-width: 520px;
  margin-top: 0;
  margin-bottom: 64px;
`

const ContentSection = styled.div`
  margin-bottom: 24px;
  border-radius: 0 0 4px 4px;
  background-color: ${props => props.theme.textInvertedColor};
`

const ButtonsContainer = styled.div`
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  display: flex;
`

export default Tabs
