// @flow
import React from 'react'
import styled from 'styled-components'
import { Field, type Values } from 'formik'

// Styling
import { solidFocus } from '../../styles/states'

type IProps = {
  type: string,
  name: string,
  placeholder: string,
  value: Values,
  onChange: () => void,
  onBlur: () => void
}

const Input = (props: IProps) => <InputField {...props} />

const InputField = styled(Field)`
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  padding: 18px 16px;
  transition: 0.5s;

  ::placeholder {
    color: ${props => props.theme.textColor};
  }

  :focus {
    ${solidFocus};
  }
`

export default Input
