// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Types
import { type IBestPractice } from '../types/data'

// Styling
import { title } from '../styles/textStyles'
import { mqFrom } from '../styles/mediaQueries'

// Containers
import DataContainer from '../containers/Data'
import ModalContainer from '../containers/Modal'

// Components
import Image from './Image'
import Pennant from './Pennant'
import Button from './Button'
import ContentBlocks from './ContentBlocks'
import BestPracticeModal from './Modal/BestPractice'
import ContentWrapperComponent from './ContentWrapper'

type IProps = IBestPractice & {
  pennantLabel: string,
  buttonText: string,
  openModal: () => void,
  className?: string
}

const BestPractice = ({
  title,
  image,
  caption,
  description,
  introduction,
  content,
  pennantLabel,
  buttonText,
  openModal,
  className
}: IProps) => (
  <Container>
    <ContentWrapper className={className}>
      <PennantComponent>{pennantLabel}</PennantComponent>
      {image && <Thumbnail src={image.url} alt={`${title} thumbnail`} />}
      <TextWrapper>
        <Title>{title}</Title>
        {description !== null && <ContentBlocks content={[description]} />}
        <Button simple onClick={openModal}>
          {buttonText}
        </Button>
      </TextWrapper>
    </ContentWrapper>
  </Container>
)

const ConnectedBestPractice = (props: IBestPractice) => (
  <Subscribe to={[DataContainer, ModalContainer]}>
    {({ state }, { open }) => {
      const openModal = () => {
        open(
          <BestPracticeModal
            pennantLabel={state.content.bestPractice.label}
            {...props}
          />
        )
      }
      return (
        <BestPractice
          {...props}
          pennantLabel={state.content.bestPractice.label}
          buttonText={state.content.bestPractice.button}
          openModal={openModal}
        />
      )
    }}
  </Subscribe>
)

const Container = styled.div`
  position: relative;

  &:nth-child(1) {
    padding: 52px 16px 0;
    ${mqFrom.px(1062)`padding: 80px 0 0;`};
  }
`

const ContentWrapper = styled(ContentWrapperComponent)`
  position: relative;
  flex-direction: column;
  display: flex;
  padding: 0;
  background-color: ${props => props.theme.textColor};
  color: ${props => props.theme.backgroundColor};

  ${mqFrom.M`flex-direction: row;`};
`

const PennantComponent = styled(Pennant)`
  position: absolute;
  top: 15px;
  z-index: 1;
  padding: 12px 20px;
  background-color: ${props => props.theme.metaColor};
  color: ${props => props.theme.textColor};
`

const Thumbnail = styled(props => <Image mode="bg" {...props} />)`
  flex-shrink: 0;
  width: 100%;
  height: 200px;

  ${mqFrom.S`height: 220px;`};

  ${mqFrom.M`width: 315px; height: auto;`};
`

const TextWrapper = styled.div`
  padding: 24px;

  p {
    margin: 0 0 45px;
  }
`

const Title = styled.h2`
  ${title};
  margin: 10px 0 20px;
`

export { BestPractice }
export default ConnectedBestPractice
