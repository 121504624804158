// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../containers/Data'

// Components
import ContentWrapper from './ContentWrapper'

// Styles
import { label } from '../styles/textStyles'
import { mqTo } from '../styles/mediaQueries'

type IProps = {
  copyrightHolder: string
}

const Footer = ({ copyrightHolder }: IProps) => {
  const currentDate = new Date().getFullYear()
  return (
    <Container>
      <Wrapper>
        <span>
          &copy; {currentDate} {copyrightHolder}. All Rights Reserved.
        </span>
      </Wrapper>
    </Container>
  )
}

const ConnectedFooter = () => (
  <Subscribe to={[DataContainer]}>
    {({ state }) => (
      <Footer copyrightHolder={state.settings.theme.copyrightHolder} />
    )}
  </Subscribe>
)

const Container = styled.footer`
  ${label}
  box-sizing: border-box;
  width: 100%;
  margin-top: auto;
  padding: 0 0 8px;
  background-color: ${props => props.theme.pageBackgroundColor};
  color: ${props => props.theme.textColor};
  text-align: right;

  ${mqTo.M`font-size: 12px;`}
  ${mqTo.px(300)`font-size: 8px;`}
`

const Wrapper = styled(ContentWrapper)`
  padding: 15px 0;
`

export default ConnectedFooter
