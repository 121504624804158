// @flow
import React from 'react'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../../containers/Data'

// Components
import Summary from '../results/Summary'
import Header from '../HomeHeader'
import Explanation from '../Explanation'
import About from '../About'

// Types
import { type IHome } from '../../types/data'

const Home = ({ header, explanation, summary, about }: IHome) => (
  <>
    <Header {...header} />
    <Explanation {...explanation} />
    <Summary {...summary} />
    <About {...about} />
  </>
)

const ConnectedHome = () => (
  <Subscribe to={[DataContainer]}>
    {({ state }) => <Home {...state.content.home} />}
  </Subscribe>
)

export { Home }
export default ConnectedHome
