// @flow
import React from 'react'
import styled from 'styled-components'

// Config
import { paths } from '../../routes'

// Styles
import {
  sectionTitle,
  subsectionUnderTitle,
  pageTitle
} from '../../styles/textStyles'
import { mqFrom } from '../../styles/mediaQueries'

// Components
import ContentWrapperComponent from '../ContentWrapper'
import ButtonComponent from '../Button'
import LinkButton from '../LinkButton'
import TotalComponent from './Total'
import Chapters from './Chapters'
import Horizons from './Horizons'

// Types
import { type ISummary } from '../../types/data'

const Summary = (props: ISummary) => (
  <>
    {props.hero === true && (
      <ContentWrapper>
        <PageTitle>{props.title}</PageTitle>
      </ContentWrapper>
    )}
    <Container>
      <ContentWrapper>
        {props.hero === undefined && <Title>{props.title}</Title>}
        {Array.from({ length: 3 }).map((e, key) => {
          const paragraph = props[`paragraph_${key + 1}`]
          let score = <Total score={props.scores.total} />
          if (key === 1) {
            score = <Chapters score={props.scores.chapters} />
          } else if (key === 2) {
            score = <Horizons score={props.scores.horizons} />
          }
          return (
            <BlockContainer key={key}>
              <TextContainer scoreOnLeft={key % 2}>
                <BlockTitle>{paragraph.title}</BlockTitle>
                <BlockText>{paragraph.text}</BlockText>
              </TextContainer>
              <ScoreContainer>{score}</ScoreContainer>
            </BlockContainer>
          )
        })}
        {props.button && (
          <Button as={LinkButton} to={paths.overview}>
            {props.button}
          </Button>
        )}
      </ContentWrapper>
    </Container>
  </>
)

const PageTitle = styled.h2`
  ${pageTitle}
  margin-top: 70px;
  margin-bottom: 60px;
`

const BlockContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-bottom: 64px;

  :last-of-type {
    margin-bottom: 40px;
  }

  ${mqFrom.L`
    justify-content: space-between;
    flex-direction: row;
  `};
`

const TextContainer = styled.div`
  flex-shrink: 0;
  align-self: center;
  max-width: 520px;
  margin: auto;

  ${mqFrom.L`
    align-self: flex-start;
    width: 44%;
    margin: auto 0;
    order: ${props => (props.scoreOnLeft ? 1 : 0)};
  `};
`

const BlockTitle = styled.h4`
  ${subsectionUnderTitle};
  margin: 0 0 30px;
`

const BlockText = styled.p`
  margin: 0;
`

const ScoreContainer = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow: hidden;

  ${mqFrom.L`
    align-self: center;
    order: ${props => (props.block % 2 === 0 ? 1 : 0)};
    margin: 0 auto;
  `};
`

const Total = styled(TotalComponent)`
  margin-top: 24px;

  ${mqFrom.L`
    margin-top: 0;
  `}
`

const Container = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;
  background: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  text-align: center;

  ${mqFrom.L`
    text-align: left;
  `};
`

const ContentWrapper = styled(ContentWrapperComponent)`
  flex-direction: column;
  display: flex;
`

const Title = styled.h2`
  ${sectionTitle};
  margin: 25px 0 64px;
`

const Button = styled(ButtonComponent)`
  align-self: center;
  margin: 0 auto;
`

export default Summary
