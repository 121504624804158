// @flow
import React from 'react'
import { Link } from 'react-router-dom'

const LinkButton = ({
  primary,
  secondary,
  simple,
  disabled,
  ...props
}: {
  primary?: boolean,
  secondary?: boolean,
  simple?: boolean,
  disabled?: boolean,
  to: string
}) => <Link {...props} />

export default LinkButton
