import React from 'react'
import Helmet from 'react-helmet'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import { Provider, Subscribe } from 'unstated'
import { ThemeProvider } from 'styled-components'

// Utils
import initGa from './utils/googleAnalytics'

// Containers
import DataContainer from './containers/Data'
import ProgressContainer from './containers/Progress'
import ModalContainer from './containers/Modal'

// Components
import App from './App'
import ProgressResetModal from './components/Modal/ProgressReset'

// Styling
import GlobalStyle from './styles'

import createRoutes from './routes'

const history = createBrowserHistory()

initGa(history)

export const AppWithProviders = (
  <Router history={history}>
    <Provider>
      <Subscribe to={[DataContainer, ProgressContainer, ModalContainer]}>
        {(data, progress, modal) => {
          if (!data.state.loading) {
            progress.validateCache(data.state.hash)

            if (progress.state.showUpdateWarning) {
              progress.shownUpdateWarning().then(() => {
                modal.open(
                  <ProgressResetModal
                    {...data.state.content.progressReset}
                    close={modal.close}
                  />
                )
              })
            }
          }

          const routes = createRoutes(data.state.chapters)

          return (
            <ThemeProvider theme={data.state.settings.theme.colors}>
              <>
                <Helmet
                  titleTemplate={`%s - ${data.state.settings.theme.title}`}
                />
                <GlobalStyle />
                <App
                  routes={routes}
                  loading={data.state.loading || progress.state.loading}
                  showUpdateWarning={progress.state.showUpdateWarning}
                  closeUpdateWarning={progress.closeUpdateWarning}
                />
              </>
            </ThemeProvider>
          )
        }}
      </Subscribe>
    </Provider>
  </Router>
)

export const init = el => {
  if (el) {
    ReactDOM.render(AppWithProviders, el)
  }
}

init(document.getElementById('root'))
