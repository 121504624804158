// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { sectionTitle, subsectionTitle } from '../../styles/textStyles'

// Components
import ContentWrapper from './ContentWrapper'
import Button from '../Button'

// Types
import { type IProgressReset } from '../../types/data'
type IModalProps = IProgressReset & {
  close: () => {}
}

const ProgressReset = ({
  title,
  subtitle,
  text,
  button,
  close
}: IModalProps) => (
  <ContentWrapper>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <Text>{text}</Text>
    <Button onClick={close}>{button}</Button>
  </ContentWrapper>
)

const Title = styled.h3`
  ${sectionTitle};
  margin: 40px 0 24px;
`

const Subtitle = styled.h4`
  ${subsectionTitle};
  margin: 0 0 2px;
`

const Text = styled.p`
  margin: 0 0 32px;
`

export default ProgressReset
