// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Formik, Form, type FormikValues, type FormikState } from 'formik'
import { Subscribe } from 'unstated'

// Config
import { paths, strToSlug } from '../../routes'

// Containers
import DataContainer from '../../containers/Data'
import ProgressContainer from '../../containers/Progress'

// Components
import BestPracticeComponent from '../BestPractice'
import StatementComponent from '../Statement'
import Header from '../ChapterHeader'
import Footer from '../ChapterFooter'

// Types
import { type Location } from 'react-router'
import { type BrowserHistory } from 'history/createBrowserHistory'

import {
  type ISettings,
  type IChapter,
  type IContentChapterBlock
} from '../../types/data'
import { type IAnswers } from '../../types/progress'

type IOwnProps = {
  location: Location,
  history: BrowserHistory
}

type IProps = {
  settings: ISettings,
  data: IChapter,
  answers: IAnswers,
  labels: IContentChapterBlock,
  backToOverview: () => void
}

const Chapter = ({
  settings,
  data,
  answers,
  labels,
  backToOverview
}: IProps) => {
  const handleValidation = values => validateChapter(values, data)
  const handleSubmit = (values, form) => {
    form.setSubmitting(false)
    backToOverview()
  }
  const initialValues = answers[data.id] || {}

  const numberOfQuestions = data.content.filter(
    ({ type }) => type === 'statement'
  ).length
  const numberOfQuestionsAnswered = Object.keys(initialValues).length
  const done = numberOfQuestions === numberOfQuestionsAnswered
  const started = numberOfQuestionsAnswered > 0
  const duration = Math.ceil(
    (data.duration / numberOfQuestions) *
      (numberOfQuestions - numberOfQuestionsAnswered)
  )

  const segments = data.content.map((segment, key) =>
    renderSegment(segment, data.id, key)
  )

  return (
    <Formik
      validate={handleValidation}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {form => (
        <Form name={data.id}>
          <ScrollToStatement form={form} />
          <Header
            title={data.title}
            description={data.description}
            duration={duration}
            numberOfQuestions={numberOfQuestions}
            labels={labels.progress}
          />
          <Background>{segments}</Background>
          <Footer
            labels={labels}
            duration={duration}
            numberOfQuestions={numberOfQuestions}
            numberOfQuestionsAnswered={numberOfQuestionsAnswered}
            done={done}
            started={started}
          />
        </Form>
      )}
    </Formik>
  )
}

type IScrollToStatementProps = {
  form: FormikState
}
class ScrollToStatement extends PureComponent<IScrollToStatementProps> {
  componentDidUpdate(prevProps: IScrollToStatementProps) {
    const { isValidating, errors } = this.props.form
    const errorsArray = Object.keys(errors)
    if (
      prevProps.form.isValidating &&
      !isValidating &&
      errorsArray.length &&
      document
    ) {
      const el = document.querySelector(`fieldset[data-id="${errorsArray[0]}"]`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }
  render() {
    return null
  }
}

const renderSegment = (segment, chapterId, key) => {
  if (segment.type === 'bestPractice') {
    return <BestPractice key={key} {...segment} />
  }
  if (segment.type === 'statement') {
    return (
      <Statement key={key} chapterId={chapterId} id={`s${key}`} {...segment} />
    )
  }
  console.error(
    `Segment with the type ${segment.type} is not supported`,
    segment
  )
  return null
}

const validateChapter = (values: FormikValues, data: IChapter) => {
  let errors = {}

  data.content.forEach((entry, key) => {
    const id = `s${key}`
    if (entry.type === 'statement' && !values[id]) {
      errors[id] = true
    }
  })

  return errors
}

const ConnectedChapter = ({ location, history }: IOwnProps) => {
  const backToOverview = () => history.push(paths.overview)
  return (
    <Subscribe to={[DataContainer, ProgressContainer]}>
      {({ state }, progress) => {
        const currentChapter = state.chapters.find(
          chapter =>
            `${paths.chapters}/${strToSlug(chapter.title)}` ===
            location.pathname
        )
        return (
          <Chapter
            settings={state.settings}
            data={currentChapter}
            labels={state.content.chapter}
            answers={progress.state.answers}
            backToOverview={backToOverview}
          />
        )
      }}
    </Subscribe>
  )
}

const Background = styled.div`
  padding-bottom: 100px;
  background-color: ${props => props.theme.backgroundColor};
`

const Statement = styled(StatementComponent)`
  margin-bottom: 52px;
`

const BestPractice = styled(BestPracticeComponent)`
  z-index: 1;
  margin-bottom: 64px;
`

export { Chapter }
export default ConnectedChapter
