// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import FocusTrap from 'focus-trap-react'

// Containers
import ModalContainer from '../../containers/Modal'

// Styling
import { fastTransition } from '../../styles/animations'
import { mqFrom } from '../../styles/mediaQueries'
import { backgroundColor } from '../../styles/colors'

// Components
import CrossIcon from '../icons/Cross'

type IProps = {
  children: React$Node,
  close: () => void,
  show: boolean
}

class Modal extends Component<IProps> {
  handleKeyPress = (e: SyntheticKeyboardEvent<*>) => {
    if (this.props.show && e.keyCode === 27) {
      this.props.close()
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress)
    if (document.body) {
      document.body.style.overflow = 'hidden'
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress)
    if (document.body) {
      document.body.style.overflow = 'auto'
    }
  }

  render() {
    const { children, close, show } = this.props

    return (
      <FocusTrap>
        <Aside show={show}>
          <Container show={show} role="dialog" aria-modal="true">
            <Backdrop onClick={close} />
            <Content show={show}>
              <Close onClick={close} aria-label="Close">
                <Cross color={backgroundColor} />
              </Close>
              {children}
            </Content>
          </Container>
        </Aside>
      </FocusTrap>
    )
  }
}

const ConnectedModal = () => (
  <Subscribe to={[ModalContainer]}>
    {({ state, close }) =>
      state.children === null ? null : (
        <Modal close={close} show={state.show}>
          {state.children}
        </Modal>
      )
    }
  </Subscribe>
)

const Aside = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  transition: opacity ${fastTransition};
  background-color: rgba(48, 48, 50, 0.7);
  opacity: ${props => (props.show ? 1 : 0)};
  overflow-x: hidden;
  overflow-y: scroll;
`

const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: ${props =>
    props.show ? 'translate(-50%, 0)' : 'translate(-50%, 100px)'};
  transition: transform ${fastTransition}, opacity ${fastTransition};
  opacity: ${props => (props.show ? 1 : 0)};
`

const Content = styled.div`
  position: relative;
  width: 90%;
  max-width: 870px;
  height: 100%;
  margin: 24px auto;
  padding-bottom: 40px;
  border-radius: 4px;
  background-color: ${props => props.theme.textInvertedColor};

  ${mqFrom.M`
    margin: 50px auto;
  `};
`

const Cross = styled(CrossIcon)`
  width: 19px;
  height: 19px;
`

const Close = styled.button`
  position: absolute;
  top: 30px;
  right: 16px;
  width: 19px;
  height: 19px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  :focus {
    outline: 0;

    /* stylelint-disable */
    > ${Cross} g {
      /* stylelint-enable */
      fill: ${props => props.theme.highlightColor};
    }
  }

  ${mqFrom.M`
    top: 35px;
    right: 40px;
  `};
`

export { Modal }
export default ConnectedModal
