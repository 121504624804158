// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Components
import ChapterBlock, { type IChapter } from './ChapterBlock'

// Containers
import DataContainer from '../containers/Data'
import ProgressContainer from '../containers/Progress'

// Types
import { type IContentChapterBlock } from '../types/data'

type IProps = {
  labels: IContentChapterBlock,
  chapters: Array<IChapter>
}

const Chapters = ({ labels, chapters }: IProps) => (
  <>
    {chapters.map(chapter => (
      <Chapter key={chapter.id} chapter={chapter} labels={labels} />
    ))}
  </>
)

const ConnectedChapters = () => (
  <Subscribe to={[DataContainer, ProgressContainer]}>
    {({ state }, progress) => {
      const chapters = state.chapters.map(chapter => {
        const numberOfQuestions = chapter.content.filter(
          ({ type }) => type === 'statement'
        ).length
        const numberOfQuestionsAnswered = chapter.content.filter((e, key) =>
          Object.keys(progress.state.answers[chapter.id] || {}).includes(
            `s${key}`
          )
        ).length
        const done = numberOfQuestions === numberOfQuestionsAnswered
        const started = numberOfQuestionsAnswered > 0
        const duration = Math.ceil(
          (chapter.duration / numberOfQuestions) *
            (numberOfQuestions - numberOfQuestionsAnswered)
        )

        return {
          id: chapter.id,
          title: chapter.title,
          description: chapter.description,
          icon: chapter.icon,
          duration,
          numberOfQuestions,
          numberOfQuestionsAnswered,
          done,
          started
        }
      })
      return (
        <Chapters
          labels={state.content.chapter}
          chapters={chapters}
          answers={progress.state}
        />
      )
    }}
  </Subscribe>
)

const Chapter = styled(ChapterBlock)`
  margin-bottom: 24px;
`

export { Chapters }
export default ConnectedChapters
