// @flow

// Global stylesheet
import { createGlobalStyle, css } from 'styled-components'
import styledNormalize from 'styled-normalize'

// Styling
import { body } from './textStyles'
import { fontWeights } from './fonts'

export const styles = css`
  ${styledNormalize}

  html,
  body {
    ${body};
    margin: 0;
    background-color: ${props => props.theme.pageBackgroundColor};
    color: ${props => props.theme.textColor};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: ${props => props.theme.highlightColor};
    font-weight: ${fontWeights.bold};
    text-decoration: none;

    :focus {
      outline: 2px dashed;
    }
  }
`

const GlobalStyle = createGlobalStyle`
  ${styles}
`

export default GlobalStyle
