// @flow
import React from 'react'
import styled, { css } from 'styled-components'

// Styles
import { button } from '../styles/textStyles'
import { textInvertedColor, darkRed, errorColor } from '../styles/colors'
import { fontWeights } from '../styles/fonts'

const buttonStyling = css`
  ${button};
  box-sizing: border-box;
  display: inline-block;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  appearance: none !important;
  ${props => props.disabled && `opacity: 0.4;`};
`

const primaryStyling = css`
  ${buttonStyling}
  padding: 22px 28px;
  transition: 0.5s;
  background-color: ${props => props.theme.highlightColor};
  color: ${textInvertedColor};

  :hover {
    background-color: ${errorColor};
  }

  :focus {
    background-color: ${darkRed};
  }

  ${props =>
    props.disabled &&
    `
    color: ${textInvertedColor};
    background-color: ${props.theme.metaColor};
    cursor: not-allowed;
  `}
`

const secondaryStyling = css`
  ${buttonStyling}
  padding: 22px 28px;
  transition: 0.5s;
  outline: 1px solid transparent;
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.highlightColor};

  :hover {
    outline: 1px solid ${props => props.theme.highlightColor};
  }

  :focus {
    outline: 1px solid ${errorColor};
    color: ${errorColor};
  }

  ${props =>
    !props.disabled &&
    `
    :hover {
      box-shadow: inset 0 0 0 1px ${props.theme.highlightColor};
    }
  `};

  ${props =>
    props.disabled &&
    `
    color: ${props.theme.metaColor};
    border: 1px solid ${props.theme.metaColor};
    cursor: not-allowed;
  `}
`

const simpleStyling = css`
  display: inline-block;
  margin-bottom: 10px;
  padding: 0;
  border: 0;
  transition: 0.5s;
  background-color: transparent;
  color: ${props => props.theme.highlightColor};
  font-weight: ${fontWeights.bold};
  text-align: left;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

type IButton = { simple?: boolean, secondary?: boolean }

const Button = styled(({ simple, primary, ...props }: IButton) => (
  <button type="button" {...props} />
))`
  ${props =>
    !props.simple
      ? !props.secondary
        ? primaryStyling
        : secondaryStyling
      : simpleStyling}
`

export default Button
