// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { mqFrom } from '../../styles/mediaQueries'

// Components
import Header from '../OverviewHeader'
import ContentWrapperComponent from '../ContentWrapper'
import Chapters from '../Chapters'
import OverviewButton from '../OverviewButton'

const Overview = () => (
  <>
    <Header />
    <Background>
      <ContentWrapper>
        <Chapters />
        <OverviewButton />
      </ContentWrapper>
    </Background>
  </>
)

const Background = styled.section`
  box-sizing: border-box;
  display: flex;

  /* set minimal height of 100vh - header height - footer height - header height */
  min-height: calc(100vh - 62px - 40px - 164px);
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: ${props => props.theme.backgroundColor};

  ${mqFrom.M`
    padding-top: 64px;
    padding-bottom: 64px;
  `};
`

const ContentWrapper = styled(ContentWrapperComponent)`
  flex-direction: column;
  display: flex;
  margin: auto;
`

export default Overview
