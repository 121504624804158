// @flow
import React from 'react'
import styled from 'styled-components'

// Config
import { paths } from '../routes'

// Styles
import { subsectionTitle, title } from '../styles/textStyles'
import { mqFrom } from '../styles/mediaQueries'
import { textColor, pageBackgroundColor } from '../styles/colors'

// Components
import Image from './Image'
import ContentWrapperComponent from './ContentWrapper'
import ButtonComponent from './Button'
import LinkButton from './LinkButton'
import PennantComponent from './Pennant'

// Types
import { type IAbout } from '../types/data'

const About = (props: IAbout) => (
  <Container>
    <ContentWrapper>
      <Title>{props.title}</Title>
      <CardContainer>
        {Array.from({ length: 3 }).map((e, key) => {
          const card = props[`card_${key + 1}`]
          return (
            <Card key={key} hasPennant={card.hasPennant}>
              <CardImage
                src={card.image.url}
                mode="bg"
                title={card.title}
                alt={card.image.alt}
              />
              {card.hasPennant && <Pennant>Best Practice</Pennant>}
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.text}</CardDescription>
            </Card>
          )
        })}
      </CardContainer>
      <Button as={LinkButton} to={paths.overview}>
        {props.button}
      </Button>
    </ContentWrapper>
  </Container>
)

const Container = styled.div`
  padding: 64px 0 80px;
`

const ContentWrapper = styled(ContentWrapperComponent)`
  flex-direction: column;
  display: flex;
`

const Title = styled.h2`
  ${subsectionTitle};
  margin: 0 0 24px;

  ${mqFrom.S`margin: 0 0 32px`};
  ${mqFrom.L`margin: 25px 0 45px`};
`

const CardContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-bottom: 42px;

  ${mqFrom.S`
    flex-direction: row;
  `};
`

const Card = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 4px;

  &:nth-child(2) {
    background-color: ${textColor};
    color: ${pageBackgroundColor};
  }

  :last-child {
    margin-bottom: 0;
  }

  ${mqFrom.S`
    width: 33.33333%;
    margin-right: 24px;
    margin-bottom: 0;

    :last-child {
      margin-right: 0;
    }
  `};
`

const CardImage = styled(Image)`
  height: 194px;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
`

const Pennant = styled(PennantComponent)`
  position: absolute;
  top: 15px;
  padding: 12px 20px;
  background-color: ${props => props.theme.metaColor};
  color: ${props => props.theme.textColor};
`

const CardTitle = styled.h4`
  ${title};
  margin: 24px 24px 16px;
`

const CardDescription = styled.p`
  margin: 0 24px 24px;
`

const Button = styled(ButtonComponent)`
  align-self: center;
  margin: 0 auto;
`

export default About
