// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { errorColor } from '../../styles/colors'
import { label } from '../../styles/textStyles'

type IProps = {
  error: string,
  className?: string
}

const ErrorMessage = ({ error, className }: IProps) => (
  <Message className={className}>{error}</Message>
)

const Message = styled.span`
  ${label};
  color: ${errorColor};
`

export default ErrorMessage
