// @flow
import React, { PureComponent } from 'react'

// Styling
import { fastDuration } from '../styles/animations'
import { textColor, pageBackgroundColor } from '../styles/colors'

// Types
type IProps = {
  color?: string,
  backgroundColor?: string
}

type Istate = {
  point1Target: number,
  point1Diff: number,
  point1: number,
  point2Target: number,
  point2Diff: number,
  point2: number,
  point3Target: number,
  point3Diff: number,
  point3: number,
  point4Target: number,
  point4Diff: number,
  point4: number
}

const FPS = 60

class Loader extends PureComponent<IProps, Istate> {
  state = {
    point1Target: 126,
    point1Diff: 0,
    point1: 126,
    point2Target: 76,
    point2Diff: 0,
    point2: 76,
    point3Target: 96,
    point3Diff: 0,
    point3: 96,
    point4Target: 66,
    point4Diff: 0,
    point4: 66
  }

  static defaultProps: IProps = {
    color: textColor,
    backgroundColor: pageBackgroundColor
  }

  unmounted: boolean = false
  targetInterval: IntervalID | null = null
  animInterval: IntervalID | null = null

  componentDidMount() {
    this.targetInterval = setInterval(() => {
      if (!this.unmounted) {
        let newState = {}

        let i = 4
        while (i--) {
          const p = i + 1

          newState['point' + p + 'Target'] = this.getRandomYPosition()
          newState['point' + p + 'Diff'] = Math.abs(
            this.state['point' + p] - newState['point' + p + 'Target']
          )
        }

        this.setState(newState)
      }
    }, fastDuration)
    // animation
    this.animInterval = setInterval(() => {
      if (!this.unmounted) {
        let newState = {}

        let i = 4
        while (i--) {
          const p = i + 1
          newState['point' + p] =
            this.state['point' + p] < this.state['point' + p + 'Target']
              ? this.state['point' + p] + this.state['point' + p + 'Diff'] / FPS
              : this.state['point' + p] - this.state['point' + p + 'Diff'] / FPS
        }

        this.setState(newState)
      }
    }, 1000 / FPS)
  }

  componentWillUnmount() {
    this.unmounted = true
    if (this.targetInterval) {
      clearInterval(this.targetInterval)
    }
    if (this.animInterval) {
      clearInterval(this.animInterval)
    }
  }

  getRandomYPosition() {
    return Math.random() * 140 + 20
  }

  render() {
    const { color, backgroundColor, ...props } = this.props
    const { point1, point2, point3, point4 } = this.state

    return (
      <svg viewBox="0 0 200 200" {...props} xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" stroke={color} strokeWidth={7}>
          {/* Graph bars */}
          <line x1="10" y1="180" x2="180" y2="180" strokeLinecap="round" />
          <line x1="10" y1="30" x2="10" y2="180" strokeLinecap="round" />

          {/* Lines */}
          <line x1="10" y1="180" x2="42" y2={point1} strokeLinecap="round" />
          <line x1="42" y1={point1} x2="82" y2={point2} strokeLinecap="round" />
          <line
            x1="82"
            y1={point2}
            x2="122"
            y2={point3}
            strokeLinecap="round"
          />
          <line
            x1="122"
            y1={point3}
            x2="162"
            y2={point4}
            strokeLinecap="round"
          />

          {/* Dots */}
          <circle fill={backgroundColor} cx={42} cy={point1} r={10} />
          <circle fill={backgroundColor} cx={82} cy={point2} r={10} />
          <circle fill={backgroundColor} cx={122} cy={point3} r={10} />
          <circle fill={backgroundColor} cx={162} cy={point4} r={10} />
        </g>
      </svg>
    )
  }
}

export default Loader
