// @flow
import React from 'react'
import styled from 'styled-components'

// Config
import { paths, strToSlug } from '../routes'

// Styling
import { subsectionTitle, label } from '../styles/textStyles'
import { mqFrom } from '../styles/mediaQueries'

// Components
import ButtonComponent from './Button'
import Chapter from './icons/Chapter'
import LinkButton from './LinkButton'

// Types
import { type IContentChapterBlock, type IChapterSummary } from '../types/data'

export type IChapter = IChapterSummary & {
  numberOfQuestions: number,
  numberOfQuestionsAnswered: number,
  done: boolean,
  started: boolean
}

type IProps = {
  labels: IContentChapterBlock,
  chapter: IChapter,
  className?: string
}

const ChapterBlock = ({ labels, chapter, className }: IProps) => {
  const {
    title,
    description,
    icon,
    duration,
    numberOfQuestions,
    numberOfQuestionsAnswered,
    done,
    started
  } = chapter

  return (
    <Block className={className}>
      <Container>
        <IconWrapper done={done}>
          <Icon icon={icon} done={done} />
        </IconWrapper>
        <TextWrapper>
          <Title done={done}>{title}</Title>
          {!done && (
            <>
              <Text>{description}</Text>
              <Progress>
                <span>
                  {started
                    ? `
                      ${numberOfQuestionsAnswered} ${labels.progress.outOf}
                      ${numberOfQuestions} ${labels.progress.questions}
                    `
                    : `
                      ${numberOfQuestions} ${labels.progress.questions}
                    `}

                  {`
                    • ${labels.progress.approx} ${duration}
                    ${labels.progress.minutes}
                  `}

                  {started && `${labels.progress.left}`}
                </span>
              </Progress>
            </>
          )}
        </TextWrapper>
      </Container>
      <Button
        as={LinkButton}
        to={`${paths.chapters}/${strToSlug(chapter.title)}`}
        secondary={done}
      >
        {done && labels.buttons.change}
        {!done && started && labels.buttons.continue}
        {!done && !started && labels.buttons.start}
      </Button>
    </Block>
  )
}

const Block = styled.div`
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  background-color: ${props => props.theme.textInvertedColor};

  ${mqFrom.M`
    flex-direction: row;
    padding: 24px 40px;
  `};
`

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;

  ${mqFrom.M`
    flex-direction: row;
  `};
`

const Title = styled.h2`
  ${subsectionTitle};
  margin: 15px 0 8px;

  ${mqFrom.M`
    ${props => props.done && `margin: 0;`}
  `};
`

const IconWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-radius: 50%;
  ${props =>
    !props.done &&
    `
    min-width: 50px;
    min-height: 50px;
    padding: 25px;
    margin: 30px;
    background-color: ${props.theme.backgroundColor};
  `};

  ${mqFrom.M`
    margin-right: 80px;
    margin-bottom: 0;
    margin-bottom: auto;
    margin-top: 15px;
  `};
`

const Icon = styled(Chapter)`
  min-width: ${props => (props.done ? '40px' : '60px')};
  height: ${props => (props.done ? '40px' : '60px')};
  margin: auto;
`

const TextWrapper = styled.div`
  text-align: center;

  ${mqFrom.M`
    text-align: left;
  `};
`

const Text = styled.p`
  min-height: 50px;
  margin: 0 0 25px;
`

const Progress = styled.div`
  ${label};
  margin-bottom: 20px;
  color: ${props => props.theme.textColor};
`

const Button = styled(ButtonComponent)`
  margin-top: auto;
  padding: 20px 28px;

  ${mqFrom.M`
    margin-left: 32px;
  `};
`

export default ChapterBlock
