// @flow
import React from 'react'

// Styling
import { textColor } from '../../styles/colors'

type IProps = {
  color?: string
}

const Cross = ({ color, ...props }: IProps) => (
  <svg viewBox="0 0 23 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill={color} fillRule="nonzero">
      <path d="M22.416.51a1 1 0 0 1 0 1.414L2.617 21.723a1 1 0 0 1-1.414-1.414L21.002.509a1 1 0 0 1 1.414 0z" />
      <path d="M1.203.51a1 1 0 0 0 0 1.414l19.799 19.799a1 1 0 0 0 1.414-1.414L2.617.509a1 1 0 0 0-1.414 0z" />
    </g>
  </svg>
)

Cross.defaultProps = {
  color: textColor
}

export default Cross
