// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../containers/Data'

// Components
import ContentWrapper from './ContentWrapper'
import LinkButton from './LinkButton'

// Styling
import { mqFrom } from '../styles/mediaQueries'

// Types
import { type ICMSImage } from '../types/data'

type IProps = {
  logo: ICMSImage,
  copyrightHolder: string
}

const Header = ({ logo, copyrightHolder }: IProps) =>
  logo ? (
    <Container>
      <ContentWrapper>
        <LinkButton to="/" rel="home">
          <Logo src={`/media/${logo.url}`} alt={logo.alt} />
        </LinkButton>
      </ContentWrapper>
    </Container>
  ) : null

const ConnectedHeader = () => (
  <Subscribe to={[DataContainer]}>
    {({ state }) => (
      <Header
        logo={state.settings.theme.logo}
        copyrightHolder={state.settings.theme.copyrightHolder}
      />
    )}
  </Subscribe>
)

const Container = styled.header`
  position: relative;
  width: 100%;
  height: 30px;
  padding: 16px 0;
  background-color: ${props => props.theme.textInvertedColor};
`

const Logo = styled.img`
  max-width: 100%;
  max-height: 30px;
  ${mqFrom.px(350)`max-width: 300px;`}
`

export default ConnectedHeader
