// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { mqFrom } from '../../styles/mediaQueries'
import { subsectionTitle } from '../../styles/textStyles'

// Types
import { type IChapters } from '../../types/data'

type IProps = {
  reportChapters: {
    [chapterId: string]: Array<string>
  },
  active: boolean,
  chapters: IChapters
}

const TabContent = ({ reportChapters, active, chapters }: IProps) => (
  <List active={active}>
    {Object.keys(reportChapters).map(chapterId => {
      const chapter = chapters.find(({ id }) => id === chapterId)
      if (!chapter) return null
      return (
        <ChapterBlock key={chapterId}>
          <Title>{chapter.title}</Title>
          <SubList>
            {reportChapters[chapterId].map(answerText => (
              <SubListItem key={answerText}>{answerText}</SubListItem>
            ))}
          </SubList>
        </ChapterBlock>
      )
    })}
  </List>
)

const ChapterBlock = styled.div`
  margin-bottom: 45px;
`

const SubList = styled.ul`
  margin-bottom: 24px;
  padding-left: 25px;
  list-style: disc;
`

const SubListItem = styled.li`
  position: relative;
  margin-bottom: 12px;
`

const Title = styled.h3`
  ${subsectionTitle}
  margin-top: 0;
  margin-bottom: 20px;
`

const List = styled.ul`
  box-sizing: border-box;
  margin: 0;
  padding: 20px;
  list-style: none;

  ${props =>
    props.active
      ? `
      display: block;
      `
      : `
      display: none;
  `}

  ${mqFrom.M`
    padding: 32px 64px;
  `};

  ${mqFrom.L`
    padding: 52px 132px;
  `};
`

export default TabContent
