// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../containers/Data'

// Styling
import { pageTitle } from '../styles/textStyles'

// Components
import ContentWrapper from './ContentWrapper'

type IProps = {
  title: string
}

const Header = ({ title }: IProps) => (
  <ContentWrapper>
    <Heading>{title}</Heading>
  </ContentWrapper>
)

const ConnectedHeader = () => (
  <Subscribe to={[DataContainer]}>
    {({ state }) => <Header title={state.content.overview.title} />}
  </Subscribe>
)

const Heading = styled.h1`
  ${pageTitle};
  margin: 80px 0 60px;
`

export { Header }
export default ConnectedHeader
