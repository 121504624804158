// @flow
import React from 'react'
import styled from 'styled-components'
import Observer from 'react-intersection-observer'

// Styles
import { scale, fastTransition } from '../../styles/animations'
import { mqTo } from '../../styles/mediaQueries'
import { pageTitle } from '../../styles/textStyles'

// Types
type IProps = {
  score: number,
  className?: string
}

const Total = ({ score, className }: IProps) => (
  <Observer rootMargin="-50px 0px -150px 0px" triggerOnce>
    {({ inView, ref }) => (
      <Container inView={inView}>
        {inView ? (
          <ScoreContainer ref={ref} className={className}>
            <Score>{score}%</Score>
          </ScoreContainer>
        ) : (
          <Placeholder ref={ref} className={className} />
        )}
      </Container>
    )}
  </Observer>
)

const Container = styled.div`
  min-width: 173px;
  transition: opacity ${fastTransition};
  opacity: 0;

  ${props =>
    props.inView &&
    `
    opacity: 1;
  `};
`

const ScoreContainer = styled.div`
  box-sizing: border-box;
  min-width: 173px;
  padding: 25px 55px;
  border-radius: 4px;
  animation: ${scale} 0.5s ease-in-out;
  background-color: ${props => props.theme.metaColor};
  color: ${props => props.theme.textColor};
  text-align: center;

  ${mqTo.L`
    display: inline-block;
  `};
`

const Score = styled.span`
  color: ${props => props.theme.textColor};
  ${pageTitle}
`

const Placeholder = styled.div`
  min-height: 105px;
`

export default Total
