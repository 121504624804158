// @flow
import { Container } from 'unstated'

// Styling
import { fastDuration } from '../styles/animations'

type IState = {
  show: boolean,
  children: React$Node | null
}

const initialState = {
  show: false,
  children: null
}

class ModalContainer extends Container<*, IState> {
  state = initialState

  open = (children: React$Node) => {
    this.setState({ children })
    setTimeout(() => {
      this.setState({ show: true })
    }, fastDuration)
  }

  close = () => {
    this.setState({ show: false })
    setTimeout(() => {
      this.setState({ children: null })
    }, fastDuration)
  }
}

export default ModalContainer
