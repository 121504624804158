import _isEqual from 'lodash.isequal'

// Validate scores
export const validate = (chapters, answers) => {
  const chaptersCheck = chapters.reduce((acc, entry) => {
    let newChapterCheck = {
      [entry.id]: [],
      ...acc
    }
    entry.content.forEach(({ type }, key) => {
      if (type === 'statement') {
        newChapterCheck[entry.id].push(`s${key}`)
      }
    })
    newChapterCheck[entry.id].sort()

    return newChapterCheck
  }, {})

  const answersCheck = Object.keys(answers).reduce(
    (acc, entry) => ({
      ...acc,
      [entry]: Object.keys(answers[entry]).sort()
    }),
    {}
  )

  const isValid = _isEqual(chaptersCheck, answersCheck)

  if (!isValid && process.env.NODE_ENV === 'development') {
    console.info('[scores] Invalid progress', { chaptersCheck, answersCheck })
  }

  return isValid
}

// Score Fetching
export const get = (chapters, answers) => {
  if (!validate(chapters, answers)) {
    throw new Error('Invalid answers for the provided chapters.')
  }

  let horizons = {
    today: { max: 0, score: 0, statements: [] },
    tomorrow: { max: 0, score: 0, statements: [] },
    beyond: { max: 0, score: 0, statements: [] }
  }
  let scores = {
    total: {
      max: 0,
      score: 0
    },
    chapters: chapters.reduce(
      (all, chapter) => ({
        ...all,
        [chapter.id]: {
          max: 0,
          score: 0,
          statements: chapter.content
            .map((entry, key) => {
              if (entry.type === 'statement') {
                const statement = {
                  weight: entry.weight,
                  score: getScorePerStatement(
                    chapters,
                    answers,
                    chapter.id,
                    key
                  )
                }
                if (horizons[entry.horizon].statements) {
                  horizons[entry.horizon].statements.push(statement)
                }
                return statement
              }
              return false
            })
            .filter(Boolean)
        }
      }),
      {}
    ),
    horizons
  }

  scores.horizons = horizons

  // Now set the scores
  let percentages = {
    total: 0,
    chapters: {},
    horizons: {
      today: 0,
      tomorrow: 0,
      beyond: 0
    }
  }
  Object.keys(scores.chapters).forEach(chapter => {
    percentages.chapters[chapter] = convertScoreToPercentage(
      setScoreBasedOnStatements(scores.chapters[chapter])
    )
  })
  Object.keys(scores.horizons).forEach(horizon => {
    scores.horizons[horizon] = setScoreBasedOnStatements(
      scores.horizons[horizon]
    )
    scores.total.max += scores.horizons[horizon].max
    scores.total.score += scores.horizons[horizon].score
    percentages.horizons[horizon] = convertScoreToPercentage(
      scores.horizons[horizon]
    )
  })
  percentages.total = convertScoreToPercentage(scores.total)

  return percentages
}

export const report = (chapters, answers) => {
  if (!validate(chapters, answers)) {
    throw new Error('Invalid answers for the provided chapters.')
  }

  let horizons = {
    today: { title: 'Today', chapters: {} },
    tomorrow: { title: 'Tomorrow', chapters: {} },
    beyond: { title: 'Beyond', chapters: {} }
  }
  chapters.forEach(({ id, title, content }) => {
    Object.keys(horizons).forEach(horizon => {
      horizons[horizon].chapters[id] = []
    })

    content.forEach((entry, key) => {
      const { type, horizon } = entry
      if (type === 'statement') {
        const selectedAnswerKey = charToScore(answers[id][`s${key}`])
        const selectedAnswerText = entry.answers[selectedAnswerKey].text
        horizons[horizon].chapters[id].push(selectedAnswerText)
      }
    })

    // remove empty chapters
    Object.keys(horizons).forEach(horizon => {
      if (horizons[horizon].chapters[id].length === 0) {
        delete horizons[horizon].chapters[id]
      }
    })
  })

  return horizons
}

// Helpers
export const scoreToChar = score => String.fromCharCode(score + 65)
export const charToScore = char => char.toUpperCase().charCodeAt(0) - 65

export const setScoreBasedOnStatements = chapterScore => ({
  ...chapterScore.statements.reduce(
    (currentScore, statement) => {
      currentScore.score += statement.weight * statement.score
      currentScore.max += statement.weight * 3
      return currentScore
    },
    { max: 0, score: 0, statements: [] }
  )
})

export const convertScoreToPercentage = scoreObj =>
  Math.round((100 / scoreObj.max) * scoreObj.score * 10) / 10

export const getScorePerStatement = (
  chapters,
  answers,
  chapterId,
  statementIndex
) => {
  const chapter = chapters.find(chapter => chapter.id === chapterId)
  const statemetAnswers =
    (chapter &&
      chapter.content &&
      chapter.content[statementIndex].answers &&
      chapter.content[statementIndex].answers) ||
    []
  return (
    statemetAnswers &&
    statemetAnswers[charToScore(answers[chapterId][`s${statementIndex}`])].score
  )
}

export default get
