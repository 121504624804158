// @flow
import React from 'react'
import styled from 'styled-components'

// Config
import { paths } from '../routes'

// Styles
import { subsectionTitle, intro } from '../styles/textStyles'
import { mqFrom } from '../styles/mediaQueries'

// Components
import ImageComponent from './Image'
import ContentWrapper from './ContentWrapper'
import Button from './Button'
import LinkButton from './LinkButton'

// Types
import { type IExplanation } from '../types/data'

const Explanation = ({ image, title, text, button }: IExplanation) => (
  <Container>
    {image !== null && (
      <Image src={image.url} mode="bg" title={title} alt={image.alt || title} />
    )}
    <ContentWrapper>
      <TextContainer>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Button as={LinkButton} to={paths.overview}>
          {button}
        </Button>
      </TextContainer>
    </ContentWrapper>
  </Container>
)

const Container = styled.div`
  position: relative;
  padding-top: 120px;
  padding-bottom: 72px;

  ${mqFrom.M`
    padding: 200px 0 72px;
  `};
`

const Image = styled(ImageComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const TextContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  max-width: 505px;
  padding: 24px;
  border-radius: 4px;
  background-color: ${props => props.theme.textInvertedColor};

  ${mqFrom.M`
    padding: 40px;
  `};
`

const Title = styled.h4`
  ${subsectionTitle};
  margin: 0 0 20px;
`

const Text = styled.p`
  ${intro};
  margin: 0 0 32px;
`

export default Explanation
