// @flow
import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'

// Config
import { paths } from '../routes'

// Utils
import { validate } from '../utils/scores'

// Containers
import DataContainer from '../containers/Data'
import ProgressContainer from '../containers/Progress'

// Styling
import { mqFrom } from '../styles/mediaQueries'

// Components
import ButtonComponent from './Button'
import LinkButton from './LinkButton'

type IProps = {
  button: string,
  active: boolean
}

const OverviewButton = (props: IProps) => (
  <ButtonWrapper disabled={!props.active}>
    <Button as={LinkButton} disabled={!props.active} to={paths.results}>
      {props.button}
    </Button>
  </ButtonWrapper>
)

const ConnectedOverviewButton = () => (
  <Subscribe to={[DataContainer, ProgressContainer]}>
    {({ state }, progress) => {
      const isActive = validate(state.chapters, progress.state.answers)
      return (
        <OverviewButton
          button={state.content.overview.button}
          active={isActive}
        />
      )
    }}
  </Subscribe>
)

const ButtonWrapper = styled.div`
  margin: 0 auto;
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    display: none;

    a {
      pointer-events: none
    }
  `}

  ${mqFrom.M`
    margin: 0 0 0 auto;
  `};
`

const Button = styled(ButtonComponent)`
  float: right;
`

export { OverviewButton }
export default ConnectedOverviewButton
