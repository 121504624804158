// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import Observer from 'react-intersection-observer'

// Styles
import { slowTransition, grow, fastTransition } from '../../styles/animations'
import { mqTo } from '../../styles/mediaQueries'

// Types
import { type IScoreHorizons } from '../../types/data'
type IProps = {
  score: IScoreHorizons,
  className?: string
}

const Horizons = ({ score, className }: IProps) => (
  <Observer rootMargin="-50px 0px -150px 0px" triggerOnce>
    {({ inView, ref }) => (
      <Container inView={inView}>
        {inView ? (
          <ScoreContainer ref={ref} className={className}>
            <Beyond>
              <Text>Beyond {score.beyond}%</Text>
            </Beyond>
            <Tomorrow>
              <Text>Tomorrow {score.tomorrow}%</Text>
            </Tomorrow>
            <Today>
              <Text>Today {score.today}%</Text>
            </Today>
          </ScoreContainer>
        ) : (
          <Placeholder ref={ref} className={className} />
        )}
      </Container>
    )}
  </Observer>
)

const Container = styled.div`
  transition: opacity ${fastTransition};
  opacity: 0;

  ${props =>
    props.inView &&
    `
    opacity: 1;
  `};
`

const ScoreContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 475px;

  ${mqTo.L`
    transform: scale(0.7);
    text-align: left;
  `};
`

const horizonStyles = css`
  position: relative;
  align-self: flex-end;
  height: 65px;
  border-radius: 70px 0 0;
`

const Beyond = styled.div`
  ${horizonStyles};
  animation: ${grow(145)} ${slowTransition};
  width: 145px;
  background: ${props => props.theme.metaColor};
`

const Tomorrow = styled.div`
  ${horizonStyles};
  animation: ${grow(245)} ${slowTransition};
  width: 245px;
  background: ${props => props.theme.highlightColor};
`

const Today = styled.div`
  ${horizonStyles};
  animation: ${grow(345)} ${slowTransition};
  width: 345px;
  background: ${props => props.theme.metaColor};
`

const Text = styled.span`
  position: absolute;
  top: 16px;
  left: -125px;
  width: 220px;
  font-size: 0.9em;
  font-weight: bold;

  &:after {
    position: absolute;
    top: 26px;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${props => props.theme.textColor};
    content: ' ';
  }
`

const Placeholder = styled.div`
  min-width: 300px;
  min-height: 202px;
`

export default Horizons
