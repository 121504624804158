// @flow
import React from 'react'

// Components
import Barchart from './Barchart'
import User from './User'
import Diamond from './Diamond'
import Gear from './Gear'
import Tools from './Tools'

// Types

export type IChapterIcon = 'Barchart' | 'Tools' | 'Diamond' | 'Gear' | 'User'

type IProps = {
  icon: IChapterIcon,
  className?: string
}

const Chapter = ({ icon, className }: IProps) => {
  switch (icon) {
    case 'Barchart':
      return <Barchart className={className} />
    case 'Tools':
      return <Tools className={className} />
    case 'Diamond':
      return <Diamond className={className} />
    case 'Gear':
      return <Gear className={className} />
    case 'User':
    default:
      return <User className={className} />
  }
}

export default Chapter
