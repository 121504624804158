// @flow
import React from 'react'
import styled from 'styled-components'

// Types
import { type ICMSHeading } from '../../types/data'

// Styling
import { subsectionTitle } from '../../styles/textStyles'

type IProps = ICMSHeading & {
  as?: string
}

const Heading = ({ text, as }: IProps) => (
  <HeadingElement as={as}>{text}</HeadingElement>
)

const HeadingElement = styled.h3`
  ${subsectionTitle};
  margin: 32px 0 12px;
`

export default Heading
