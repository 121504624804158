// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { title } from '../../styles/textStyles'
import { mqFrom } from '../../styles/mediaQueries'
import { metaColor, textColor, backgroundColor } from '../../styles/colors'

// Types
import { type IHorizon } from '../../types/data'

type ITabButtonProps = {
  title: string,
  horizonId: IHorizon,
  active: boolean,
  handleClick: (horizonId: IHorizon) => void
}

const TabButton = (props: ITabButtonProps) => (
  <Button
    active={props.active}
    onClick={() => props.handleClick(props.horizonId)}
  >
    {props.title}
  </Button>
)

const Button = styled.button`
  ${title};
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 16px;

  :focus {
    outline: 2px dashed ${textColor};
  }

  :first-child,
  :last-child {
    &:before {
      position: absolute;
      bottom: 0;
      z-index: 1;
      width: 1px;
      height: 64px;
      background-color: white;
      content: '';
    }
  }

  :first-child {
    border-top-left-radius: 4px;

    &:before {
      right: -1px;
    }
  }

  :last-child {
    border-top-right-radius: 4px;

    &:before {
      left: -1px;
    }
  }

  ${props =>
    props.active
      ? `
      background-color: ${metaColor};
      color: ${textColor};
      pointer-events: none;

      &:last-child:before,
      &:first-child:before
      {
        content: none;
      }

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        background-color: white;
        height: 3px;
        bottom: -2px;
        left: 0;
      }
    `
      : `
      background-color: ${textColor};
      color: ${backgroundColor};
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }
  `};

  ${mqFrom.M`
    font-size: 24px;
    padding: 18px 10px;
  `};
`

export default TabButton
