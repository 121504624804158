// @flow
import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { Switch, Route } from 'react-router'

import { type Route as RouteObjectType } from './routes'

// Utils
import { isClient } from './utils/getRenderPlatform'

// Components
import Header from './components/Header'
import Loading from './components/pageTypes/Loading'
import Footer from './components/Footer'
import Modal from './components/Modal'

type IProps = {
  routes: Array<RouteObjectType>,
  loading: boolean
}

const COMMIT = process.env.COMMIT || 'N.A.'
const VERSION = process.env.VERSION || 'N.A.'

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test' && isClient()) {
  console.info(`
     ___  _       _ __       __
    / _ \\(_)___  (_) /____ _/ /
   / // / / _  \`/ / __/ _ \`/ /
  /____/_/\\\\_, /_/\\__/\\_,_/_/
     __   /___/  __           _ __
    /  |/  /__ _/ /___ ______(_) /___ __
   / /\\,/ / _ \`/ __/ // / __/ / __/ // /
  /_/  /_/\\_,_/\\__/\\_,_/_/ /_/\\__/\\_, /
                                 /___/

  by: Jungle Minds
  Version: ${VERSION}
  Commit: ${COMMIT}
  `)
}

export class AppRoute extends PureComponent<RouteObjectType> {
  componentDidMount() {
    if (window) {
      setTimeout(() => {
        // Move to back of callstack
        window.scrollTo(0, 0)
      }, 0)
    }
  }
  render() {
    const { title, path } = this.props
    const RouteComponent = this.props.component
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Route
          path={path}
          render={(props: *) => <RouteComponent {...props} />}
        />
      </>
    )
  }
}

const App = ({ routes, loading }: IProps) => (
  <>
    {!loading && <Header />}
    <Switch>
      {loading ? (
        <Loading />
      ) : (
        routes.map((route, key) => {
          // only pass allowed route props to Route component
          const { ...routeProps } = route
          return <AppRoute key={key} {...routeProps} />
        })
      )}
    </Switch>
    <Footer />
    <Modal />
  </>
)

export default App
