// @flow
import React from 'react'

// Styling
import { textColor } from '../../styles/colors'

type IProps = {
  color?: string
}

const Diamond = ({ color, ...props }: IProps) => (
  <svg viewBox="0 0 75 66" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill={color} fillRule="evenodd">
      <path
        d="M62.877 11.643a1.482 1.482 0 0 1 .758.09l5.465 2.16c.784.31 1.176 1.19.881 1.98a1.489 1.489 0 0 1-1.942.865l-5.162-2.04-5.161 2.04a1.489 1.489 0 0 1-1.942-.864c-.295-.79.097-1.671.881-1.981l5.465-2.16a1.489 1.489 0 0 1 .757-.09zm0-8.029l5.162-2.04a1.489 1.489 0 0 1 1.942.864c.295.79-.097 1.671-.881 1.981l-5.465 2.16a1.489 1.489 0 0 1-.758.09 1.482 1.482 0 0 1-.757-.09l-5.465-2.16a1.548 1.548 0 0 1-.881-1.98 1.489 1.489 0 0 1 1.942-.865l5.161 2.04zM51.73 1.082c.375.118.703.387.892.776l.986 2.032c.366.753.07 1.668-.661 2.044a1.46 1.46 0 0 1-1.986-.681l-.184-.379-3.502 14.428h15.382l.262-.378a1.452 1.452 0 0 1 2.064-.354c.55.404.75 1.123.535 1.748.055.158.084.33.084.507 0 .822-.631 1.492-1.422 1.523L38.197 59.806c-.263.38-.664.598-1.082.633a1.454 1.454 0 0 1-1.601.427 1.464 1.464 0 0 1-.147.044c-.795.197-1.594-.306-1.785-1.124l-.363-1.551-26.038-37.54a1.553 1.553 0 0 1 .344-2.125c.187-.138.394-.224.606-.262l12.148-16.36a1.613 1.613 0 0 1-.056-.424c0-.842.642-1.524 1.435-1.524h28.698c.648 0 1.195.456 1.373 1.082zm-6.865 15.58L48.17 3.049H37.427l7.437 13.615zm-1.38 5.687h-15.61l7.658 32.756 7.952-32.756zm3.05 0l-7.542 31.07 21.55-31.07H46.534zm-22.416-3.047L21.005 5.988l-9.887 13.314h13zM22.992 3.287c.312.205.55.53.643.928l2.796 11.959L33.6 3.048H23.097a1.537 1.537 0 0 1-.106.24zM24.83 22.35H11.965l19.406 27.977-6.54-27.977zm18.08-3.047L35.514 5.76l-7.396 13.54H42.91zM1.48 65.016c-.818 0-1.48-.682-1.48-1.524s.662-1.524 1.48-1.524h72.013c.817 0 1.48.682 1.48 1.524s-.663 1.524-1.48 1.524H1.48z"
        fillRule="nonzero"
      />
      <path
        d="M60.534 9.654l-1.989 5.295a1.549 1.549 0 0 1-1.965.916 1.487 1.487 0 0 1-.898-1.926l2.068-5.505c.031-.082.069-.16.112-.234L55.794 3.68a1.487 1.487 0 0 1 .76-1.983 1.549 1.549 0 0 1 2.025.777l2.447 5.347a1.487 1.487 0 0 1-.492 1.832zm8.048-.33l2.326 5.083a1.487 1.487 0 0 1-.76 1.983 1.549 1.549 0 0 1-2.025-.777l-2.447-5.347a1.487 1.487 0 0 1-.128-.766 1.48 1.48 0 0 1 .054-.873l2.068-5.505a1.549 1.549 0 0 1 1.965-.916 1.487 1.487 0 0 1 .897 1.926l-1.95 5.191z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

Diamond.defaultProps = {
  color: textColor
}

export default Diamond
