// @flow
import React from 'react'

// Styling
import { textColor } from '../../styles/colors'

type IProps = {
  color?: string
}

const Barchart = ({ color, ...props }: IProps) => (
  <svg viewBox="0 0 77 59" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M44 55.5h7v-18a1.5 1.5 0 0 1 3 0v18h9v-23a1.5 1.5 0 0 1 3 0v23h9a1.5 1.5 0 0 1 0 3H2a1.5 1.5 0 0 1 0-3h7v-8a1.5 1.5 0 0 1 3 0v8h9v-10a1.5 1.5 0 0 1 3 0v10h5v-12a1.5 1.5 0 0 1 3 0v12h9v-15a1.5 1.5 0 0 1 3 0v15zM3.465 41.071c10.518-1.081 22.034-3.337 34.547-6.766 11.527-3.16 22.2-6.85 32.021-11.07.444-.19 1.316-.574 2.615-1.152a.961.961 0 0 1 1.352.878c0 .899-.539 1.71-1.367 2.059-12.524 5.27-24.052 9.348-34.585 12.235-10.505 2.879-22.03 5.124-34.577 6.734A1.305 1.305 0 0 1 2 42.695c0-.837.633-1.538 1.465-1.624zM16 5h6a2 2 0 0 1 2 2v27.5a1.5 1.5 0 0 1-3 0V8h-9v28.5a1.5 1.5 0 0 1-3 0V7a2 2 0 0 1 2-2h5zm42-5h6a2 2 0 0 1 2 2v18.5a1.5 1.5 0 0 1-3 0V3h-9v22.5a1.5 1.5 0 0 1-3 0V2a2 2 0 0 1 2-2h5zM36 19h6a2 2 0 0 1 2 2v7.5a1.5 1.5 0 0 1-3 0V22h-9v10.5a1.5 1.5 0 0 1-3 0V21a2 2 0 0 1 2-2h5z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
)

Barchart.defaultProps = {
  color: textColor
}

export default Barchart
