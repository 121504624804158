// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../containers/Data'
import ProgressContainer from '../containers/Progress'

// import this for reload purposes
import './utils/generatePDF'

// MockData
import mockChapters from '../utils/_mocks/chapters.json'
import mockAnswers from '../utils/_mocks/answers.json'

// Types
import { type Location } from 'react-router'
import { type IChapters, type IPDFContent } from '../types/data'
import { type IAnswers } from '../types/progress'
type IOwnProps = {
  location: Location
}
type IProps = {
  content: IPDFContent,
  chapters: IChapters,
  answers: IAnswers
}
type IState = {
  src: string
}

// Constants
const API_URL = process.env.REACT_APP_API_URL || ''

class PdfGeneratorDevPage extends PureComponent<IProps, IState> {
  state = {
    src: ''
  }

  fetchNewUrl = async () => {
    const { content, chapters, answers } = this.props

    try {
      const {
        data: { src }
      } = await axios({
        url: `${API_URL}/dev/pdf-generator`,
        method: 'POST',
        data: {
          name: 'Marvin',
          email: 'marvin.de.bruin@jungleminds.nl',
          content,
          chapters,
          answers
        }
      })
      this.setState({
        src
      })
    } catch (err) {
      console.error(err.response.data.error)
    }
  }

  componentDidMount() {
    // Give Server a second to restart
    setTimeout(this.fetchNewUrl, 1000)
  }

  render() {
    return <Preview title="PDF Test" src={this.state.src} />
  }
}

const ConnectedPdfGeneratorDevPage = ({ location }: IOwnProps) => {
  const useMockData = location.search === '?mock'
  return (
    <Subscribe to={[DataContainer, ProgressContainer]}>
      {(data, progress) => (
        <PdfGeneratorDevPage
          content={data.state.content.PDF}
          chapters={useMockData ? mockChapters : data.state.chapters}
          answers={useMockData ? mockAnswers : progress.state.answers}
        />
      )}
    </Subscribe>
  )
}

const Preview = styled.iframe`
  width: 100%;
  height: 100vh;
`

export default ConnectedPdfGeneratorDevPage
