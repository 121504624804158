// @flow
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// Components
import ContentWrapper from '../ContentWrapper'

// Styles
import { pageTitle, sectionTitle, intro } from '../../styles/textStyles'

const PageNotFound = () => (
  <>
    <ContentWrapper>
      <Heading>Oops!</Heading>
    </ContentWrapper>
    <Background>
      <ContentWrapper>
        <Code>404</Code>
        <Text>
          Sorry, we can not seem to find the page you are looking for.
        </Text>
        <Text>
          Click <Link to="/">here</Link> to return to the homepage.
        </Text>
      </ContentWrapper>
    </Background>
  </>
)

const Heading = styled.h1`
  ${pageTitle};
  margin: 80px 0 40px;
`

const Background = styled.section`
  align-items: center;
  display: flex;

  /* set minimal height of 100vh - header height - footer height - heading height */
  min-height: calc(100vh - 62px - 40px - 164px);
  padding: 32px 0;
  background-color: ${props => props.theme.backgroundColor};
`

const Code = styled.h2`
  ${sectionTitle};
  margin: 0 0 32px;
`

const Text = styled.p`
  /* ${intro}; */
  margin: 0 0 16px;

  :last-child {
    margin: 0;
  }
`

export default PageNotFound
