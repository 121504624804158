// @flow
import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Subscribe } from 'unstated'
import { string as YupString, object as YupObject } from 'yup'

// Utils
import { requestReport } from '../../utils/api'

// Containers
import DataContainer from '../../containers/Data'
import ProgressContainer from '../../containers/Progress'
import ModalContainer from '../../containers/Modal'

// Components
import Input from './Input'
import ErrorMessage from './ErrorMessage'
import ButtonComponent from '../Button'
import SentResults from '../Modal/SentResults'

// Styling
import { mqFrom } from '../../styles/mediaQueries'
import { fastTransition } from '../../styles/animations'
import { button } from '../../styles/textStyles'

// Types
import { type IChapters, type IEmailContent } from '../../types/data'
import { type IAnswers } from '../../types/progress'

type IOwnProps = {
  button: string
}

type IProps = IOwnProps & {
  content: IEmailContent,
  chapters: IChapters,
  answers: IAnswers,
  hash: string,
  hostname: string,
  openModal: () => void
}

const handleFormSubmission = async (
  values,
  form,
  openModal,
  content,
  chapters,
  answers,
  hash,
  hostname
) => {
  const result = await requestReport(
    values.name,
    values.email,
    content,
    chapters,
    answers,
    hash,
    hostname
  )
  form.setSubmitting(false)

  if (result.error) {
    form.setStatus(new Error(result.error))
    return false
  }

  openModal()
}

const validationSchema = YupObject().shape({
  name: YupString().max(254, 'Name appears to be over the max. 254 characters'),
  email: YupString()
    .email('E-mail address is not valid')
    .max(254, 'E-mail address appears to be over the max. 254 characters')
    .required('E-mail address field is required')
})

const initialValues = { name: '', email: '' }

const RequestReport = ({
  button,
  openModal,
  content,
  chapters,
  answers,
  hash,
  hostname
}: IProps) => {
  const submit = (values, form) =>
    handleFormSubmission(
      values,
      form,
      openModal,
      content,
      chapters,
      answers,
      hash,
      hostname
    )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status
      }) => {
        const error =
          status instanceof Error || (errors.email && touched.email)
            ? (status && status.message) || errors.email
            : ''
        return (
          <Form onSubmit={handleSubmit}>
            <InputWrapper>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">E-mail address</Label>
                <Input
                  type="email"
                  name="email"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </FormGroup>
            </InputWrapper>
            <ErrorWrapper show={error !== ''}>
              {error !== '' && <ErrorMessage error={error} />}
            </ErrorWrapper>
            <Button type="submit" disabled={isSubmitting}>
              {button}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const ConnectedRequestReport = (props: IOwnProps) => (
  <Subscribe to={[DataContainer, ProgressContainer, ModalContainer]}>
    {(data, progress, { open }) => {
      const openModal = () => {
        open(<SentResults />)
      }
      return (
        <RequestReport
          {...props}
          content={data.state.content.PDF}
          chapters={data.state.chapters}
          answers={progress.state.answers}
          hash={data.state.hash}
          hostname={data.state.settings.theme.hostname}
          openModal={openModal}
        />
      )
    }}
  </Subscribe>
)

const Form = styled.form`
  position: relative;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-bottom: 24px;

  ${mqFrom.L`
    flex-direction: row;
  `};
`

const Label = styled.label`
  ${button}
  width: 100%;
`

const InputWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;

  ${mqFrom.M`
    flex-direction: row;
  `};

  ${mqFrom.L`
    width: auto;
  `};
`

const FormGroup = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  ${mqFrom.M`
    width: 50%;
    margin-right: 24px;

    :last-child {
      margin-right: 0;
    }
  `};

  ${mqFrom.L`
    width: 300px;
    margin-bottom: 0;

    :last-child {
      margin-right: 24px;
    }
  `};
`

const Button = styled(ButtonComponent)`
  margin-top: auto;
  margin-right: auto;
`

const ErrorWrapper = styled.div`
  width: 100%;
  margin-top: -10px;
  margin-bottom: 16px;
  transform: translateY(-10px);
  transition: opacity ${fastTransition}, transform ${fastTransition};
  opacity: 0;

  ${mqFrom.L`
    position: absolute;
    top: 150px;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
  `};

  ${props =>
    props.show &&
    `
    transform: translateY(0);
    opacity: 1;
  `};
`

export { RequestReport }
export default ConnectedRequestReport
