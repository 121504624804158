import styled from 'styled-components'

import { mqFrom } from '../../styles/mediaQueries'
import { backgroundColor, textColor } from '../../styles/colors'

const ContentWrapper = styled.div`
  padding-right: 16px;
  padding-left: 16px;

  background-color: ${props => (props.theme.dark === true ? textColor : '')};
  color: ${props => (props.theme.dark === true ? backgroundColor : '')};

  ${mqFrom.M`
    padding-right: 88px;
    padding-left:  88px;
  `};
`

export default ContentWrapper
