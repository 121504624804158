// @flow
import React from 'react'
import styled from 'styled-components'
import Observer from 'react-intersection-observer'
import { Subscribe } from 'unstated'

// Containers
import DataContainer from '../../containers/Data'

// Styles
import { textColor } from '../../styles/colors'
import { button } from '../../styles/textStyles'
import { mqFrom } from '../../styles/mediaQueries'
import { grow, fastTransition } from '../../styles/animations'

// Types
import { type IScoreChapters, type IChapterInfo } from '../../types/data'

type IOwnProps = {
  score: IScoreChapters
}
type IProps = IOwnProps & {
  chapters: Array<IChapterInfo>
}

const Chapters = (props: IProps) => (
  <>
    {props.chapters.map((chapter, i) => {
      const isDark = i % 2
      return (
        <Chapter key={i}>
          <ChapterTitle>{chapter.title}</ChapterTitle>
          <ChapterScore
            score={props.score[chapter.id]}
            isDark={isDark}
            index={i}
          />
        </Chapter>
      )
    })}
    <Info>Fully capable</Info>
  </>
)

const ConnectedChapters = ({ score }: IOwnProps) => (
  <Subscribe to={[DataContainer]}>
    {({ state }) => {
      const chapters = state.chapters.map(chapter => ({
        id: chapter.id,
        title: chapter.title,
        icon: chapter.icon
      }))
      return (
        <Observer rootMargin="-50px 0px -150px 0px" triggerOnce>
          {({ inView, ref }) => (
            <Container inView={inView}>
              {inView ? (
                <ScoreContainer ref={ref}>
                  <Chapters chapters={chapters} score={score} />
                </ScoreContainer>
              ) : (
                <ScoreContainer ref={ref} />
              )}
            </Container>
          )}
        </Observer>
      )
    }}
  </Subscribe>
)

const Container = styled.div`
  margin-bottom: -46px;
  transition: opacity ${fastTransition};
  opacity: 0;

  ${mqFrom.L`
    margin-bottom: 0;
  `};

  ${props =>
    props.inView &&
    `
    opacity: 1;
  `};
`

const ScoreContainer = styled.div`
  position: relative;
  min-width: 300px;
  max-width: 510px;
  min-height: 265px;
  margin-right: 40px;
  transform: scale(0.7);

  ${mqFrom.L`
    transform: scale(0.9);
  `};
`

const Chapter = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const ChapterTitle = styled.div`
  ${button};
  align-self: center;
  min-width: 150px;
  padding-right: 25px;
  text-align: right;
`

const ChapterScore = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 320px;
  height: 43px;
  padding: 3px;
  border-radius: 4px;
  background-color: #fff;

  &:after {
    position: absolute;
    top: 3px;
    left: 3px;
    animation: ${grow(props => (props.score ? props.score : 0))}
      ${props => `0.${props.index + 3}s ease-in-out`};
    width: calc(${props => (props.score ? props.score : 0)}% - 6px);
    height: calc(100% - 6px);
    border-radius: none;
    background-color: ${props =>
      props.isDark ? props.theme.metaColor : props.theme.highlightColor};
    content: '';
  }
`

const Info = styled.span`
  position: absolute;
  top: -45px;
  right: 0;
  color: ${textColor};
  font-size: 14px;
`

export { Chapters }
export default ConnectedChapters
