// @flow
import React from 'react'
import { Subscribe } from 'unstated'
import { Redirect } from 'react-router'

// Config
import { paths } from '../../routes'

// Utils
import * as scoresUtil from '../../utils/scores'

// Containers
import DataContainer from '../../containers/Data'
import ProgressContainer from '../../containers/Progress'

// Components
import RequestReport from '../RequestReport'
import Summary from '../results/Summary'
import Tabs from '../results/Tabs'

// Types
import { type ISummary, type IReport, type IChapters } from '../../types/data'

type IProps = {
  content: ISummary,
  chapters: IChapters,
  report: IReport
}

const ResultsPage = ({ content, report, chapters }: IProps) => (
  <>
    <Summary {...content} />
    <RequestReport />
    <Tabs report={report} content={content.horizons} chapters={chapters} />
  </>
)

const ConnectedResultsPage = () => (
  <Subscribe to={[DataContainer, ProgressContainer]}>
    {({ state }, progress) => {
      const isValid =
        !state.loading &&
        !progress.state.loading &&
        scoresUtil.validate(state.chapters, progress.state.answers)
      if (isValid) {
        const scores = scoresUtil.get(state.chapters, progress.state.answers)
        const horizons = state.content.results.horizons
        const report = scoresUtil.report(state.chapters, progress.state.answers)
        const content = {
          scores,
          ...state.content.results.score,
          horizons
        }
        const chapters = state.chapters

        return (
          <ResultsPage content={content} report={report} chapters={chapters} />
        )
      }
      return <Redirect to={paths.overview} />
    }}
  </Subscribe>
)

export { ResultsPage }
export default ConnectedResultsPage
