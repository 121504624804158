// @flow
import React from 'react'
import styled from 'styled-components'

// Styling
import { pageTitle, label } from '../styles/textStyles'

// Components
import ContentWrapper from './ContentWrapper'

// Types
import { type IContentProgress } from '../types/data'

type IProps = {
  title: string,
  description: string,
  duration: number,
  numberOfQuestions: number,
  labels: IContentProgress
}

const ChapterHeader = ({
  title,
  description,
  duration,
  numberOfQuestions,
  labels
}: IProps) => (
  <Container>
    <ContentWrapper>
      <Title>{title}</Title>
      <Text>{description}</Text>
      <Progress>
        {`
          ${numberOfQuestions} ${labels.questions} •
          ${labels.approx} ${duration} ${labels.minutes}
        `}
      </Progress>
    </ContentWrapper>
  </Container>
)

const Container = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;
`

const Title = styled.h1`
  ${pageTitle};
  margin-top: 0;
  margin-bottom: 8px;
`

const Text = styled.p`
  margin-top: 0;
  margin-bottom: 20px;
`

const Progress = styled.span`
  ${label};
  margin-bottom: 80px;
  color: ${props => props.theme.textColor};
`

export default ChapterHeader
