// @flow
import { Container } from 'unstated'
import localForage from 'localforage'

// Types
import { type IProgressState } from '../types/progress'
type IProps = IProgressState & {
  showUpdateWarning: boolean
}

const DEV = process.env.NODE_ENV === 'development'

const initialState = {
  showUpdateWarning: false,
  loading: true,
  hash: '',
  answers: {}
}

class ProgressContainer extends Container<*, IProps> {
  validated: boolean = false
  store = localForage.createInstance({
    name: 'DigitalMaturity',
    storeName: 'dm'
  })
  state = initialState

  setAnswer = async (
    chapterId: string,
    statementId: string,
    answer: string
  ) => {
    if (DEV) {
      console.info(
        `[progress] Setting answer: '${answer}' for ${chapterId}.${statementId}.`
      )
    }
    await this.setState({
      answers: {
        ...this.state.answers,
        [chapterId]: {
          ...(this.state.answers[chapterId] || {}),
          [statementId]: answer
        }
      }
    })
    this.store.setItem('answers', this.state.answers)
  }

  validateCache = async (dataHash: string) => {
    if (!this.validated) {
      this.validated = true
      const clientHash = await this.store.getItem('hash')

      if (dataHash !== clientHash) {
        let newCache = clientHash === null
        if (DEV) {
          console.info(
            newCache
              ? '[progress] No cache found. Setting up cache.'
              : '[progress] Cache is not valid. Rebuilding cache.'
          )
        }

        await this.store.clear()
        await this.store.setItem('hash', dataHash)
        await this.store.setItem('answers', {})
        await this.setState({
          loading: false,
          hash: dataHash,
          answers: {},
          showUpdateWarning: !newCache
        })
      } else {
        if (DEV) {
          console.info('[progress] Cache is valid. Loading cache.')
        }
        const answers = await this.store.getItem('answers')
        await this.setState({ loading: false, hash: dataHash, answers })
      }
    }

    return this.state
  }

  shownUpdateWarning = async () => {
    await this.setState({ showUpdateWarning: false })
  }
}

export default ProgressContainer
