import axios from 'axios'

// Constants
const API_URL = process.env.REACT_APP_API_URL || ''

export const requestReport = async (
  name,
  email,
  content,
  chapters,
  answers,
  hash,
  hostname
) => {
  try {
    const { data } = await axios({
      url: API_URL,
      method: 'POST',
      data: {
        name,
        email,
        content,
        chapters,
        answers,
        hash,
        hostname
      }
    })

    return data
  } catch (err) {
    if (err.response && err.response.data && err.response.error) {
      console.error(err.response.data)
      return err.response.data
    }
    return {
      error: 'Something went wrong sending the report.'
    }
  }
}
