// @flow
import React from 'react'
import styled from 'styled-components'
import PrismicRichText from 'prismic-richtext'

// Types
import {
  type ICMSParagraph,
  type ICMSSpansAll,
  type ICMSElementTypes,
  type ICMSSpansData
} from '../../types/data'

// Styling
import { label, title } from '../../styles/textStyles'
import { fontWeights } from '../../styles/fonts'
import { mqFrom } from '../../styles/mediaQueries'

type IProps = ICMSParagraph & {
  as?: string
}

type ITree = {
  key: string,
  element: ICMSParagraph,
  text: string,
  children: Array<ITree>
}

const convertRichTextToElements = (
  text: string,
  spans: Array<ICMSSpansAll>
): Array<React$Element<*>> => {
  let elements = []
  const tree: ITree = PrismicRichText.asTree([
    { type: 'paragraph', text, spans }
  ])

  const createElement = (
    key: string,
    type: ICMSElementTypes,
    text: string,
    data: ?ICMSSpansData
  ) => {
    switch (type) {
      case 'hyperlink': {
        if (data) {
          if (
            (data.link_type === 'Web' || data.link_type === 'Media') &&
            data.url
          ) {
            return (
              <a
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
                key={key}
              >
                {text}
              </a>
            )
          }
        }
        return <span key={key}>{text}</span>
      }
      case 'strong': {
        return <strong key={key}>{text}</strong>
      }
      case 'em': {
        return <em key={key}>{text}</em>
      }
      case 'highlight': {
        return <Highlight key={key}>{text}</Highlight>
      }
      default: {
        return <span key={key}>{text}</span>
      }
    }
  }

  const traverseTree = (subTree: ITree): void => {
    if (subTree.children.length > 1 || subTree.element.type === 'paragraph') {
      subTree.children.forEach(child => traverseTree(child))
    } else {
      let elementType = subTree.element.type

      // When an element has EM and STRONG it is considered a highlight component
      if (
        (elementType === 'em' || elementType === 'strong') &&
        subTree.children.length &&
        subTree.children[0].element &&
        (subTree.children[0].element.type === 'em' ||
          subTree.children[0].element.type === 'strong')
      ) {
        elementType = 'highlight'
      }

      elements.push(
        createElement(
          subTree.key,
          elementType,
          subTree.text,
          subTree.element.type === 'hyperlink' ? subTree.element.data : null
        )
      )
    }
  }

  traverseTree(tree.children[0])

  return elements
}

const Paragraph = ({ text, spans, as }: IProps) => {
  const elements = convertRichTextToElements(text, spans)
  return <ParagraphElement as={as}>{elements}</ParagraphElement>
}

const ParagraphElement = styled.p`
  ${label};
  margin: 0 0 16px;
  line-height: 1.5;

  a {
    transition: 0.5s;
    font-weight: ${fontWeights.bold};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Highlight = styled.span`
  ${title};
  position: relative;
  display: block;
  margin-top: 32px;
  margin-bottom: 24px;
  padding: 16px;

  ${mqFrom.M`
    padding: 16px 40px;
  `};

  :before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: ${props => props.theme.highlightColor};
    content: '';
  }
`

export default Paragraph
