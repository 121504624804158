// @flow

// When the environment is 'test' the isServer will give a false negative
// You can now test SSR related stuff by setting the NODE_ENV to something other than test in order to test it

export const isServer = () =>
  process.env.NODE_ENV === 'test'
    ? false
    : !!(typeof module !== 'undefined' && module.exports)
export const isClient = () => !isServer()
