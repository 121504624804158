// @flow
import React from 'react'

// Styling
import { textInvertedColor } from '../../styles/colors'

type IProps = {
  color?: string
}

const Exclamation = ({ color, ...props }: IProps) => (
  <svg viewBox="0 0 4 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill={color} fillRule="evenodd">
      <path d="M1.017 0h1.966a1 1 0 0 1 .998 1.073l-.54 7.375a1 1 0 0 1-.998.927h-.886a1 1 0 0 1-.998-.927L.02 1.073A1 1 0 0 1 1.017 0z" />
      <ellipse cx={2} cy={13.125} rx={2} ry={1.875} />
    </g>
  </svg>
)

Exclamation.defaultProps = {
  color: textInvertedColor
}

export default Exclamation
